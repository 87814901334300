<app-sleep-upload-form
  *ngIf="type==='SLEEP'"
  [formConfig]="formConfig!"
  [showPostMeasurementFields]="showPostMeasurementFields"
  [showPreMeasurementFields]="showPreMeasurementFields"
></app-sleep-upload-form>

<app-holter-ecg-upload-form
  *ngIf="type==='HOLTER_ECG'"
  [formConfig]="formConfig!"
  [showPostMeasurementFields]="showPostMeasurementFields"
  [showPreMeasurementFields]="showPreMeasurementFields"
  [isHome]="isHome"
></app-holter-ecg-upload-form>

<app-ecg-atrial-fibrillation-upload-form
  *ngIf="type==='ECG_ATRIAL_FIBRILLATION'"
  [formConfig]="formConfig!"
  [showPostMeasurementFields]="showPostMeasurementFields"
  [showPreMeasurementFields]="showPreMeasurementFields"
  [isHome]="isHome"
></app-ecg-atrial-fibrillation-upload-form>

<app-symptom-holter-upload-form
  *ngIf="type==='SYMPTOM_HOLTER'"
  [formConfig]="formConfig!"
  [showPostMeasurementFields]="showPostMeasurementFields"
  [showPreMeasurementFields]="showPreMeasurementFields"
  [isHome]="isHome"
></app-symptom-holter-upload-form>

<app-blood-pressure-upload-form
  *ngIf="type==='BLOOD_PRESSURE'"
  [formConfig]="formConfig!"
  [showPostMeasurementFields]="showPostMeasurementFields"
  [showPreMeasurementFields]="showPreMeasurementFields"
  [isHome]="isHome"
></app-blood-pressure-upload-form>
