<div data-testid="form-SYMPTOM_HOLTER">

  <div
    data-testid="pre-measurement-form-SYMPTOM_HOLTER"
    *ngIf="showPreMeasurementFields"
  >

    <div *ngIf="!isEForm">
      <form-field [config]="formConfig.measurementStartTime"></form-field>
      <hr/>
    </div>

    <form-field [config]="formConfig.reasonForStudy"></form-field>
    <form-field [config]="formConfig.gender"></form-field>
    <form-field [config]="formConfig.age"></form-field>
    <form-field [config]="formConfig.height"></form-field>
    <form-field [config]="formConfig.weight"></form-field>

    <hr/>

    <form-field [config]="formConfig.smoking"></form-field>

    <hr/>

    <form-header [label]="formConfig.frequencyHeaderLabel"
                 [columnLabels]="formConfig.frequencyHeaderColumnLabels"></form-header>

    <form-field [config]="formConfig.abnormalHeartbeats"></form-field>
    <form-field [config]="formConfig.slowPulse"></form-field>
    <form-field [config]="formConfig.fastPulse"></form-field>
    <form-field [config]="formConfig.palpitation"></form-field>
    <form-field [config]="formConfig.irregularPulse"></form-field>
    <form-field [config]="formConfig.dizziness"></form-field>
    <form-field [config]="formConfig.lossOfConsciousness"></form-field>
    <form-field [config]="formConfig.blackouts"></form-field>
    <form-field [config]="formConfig.chestPain"></form-field>
    <form-field [config]="formConfig.shortnessOfBreath"></form-field>

    <hr/>

    <form-field [config]="formConfig.diagnosedHeartDisease"></form-field>

    <hr/>

    <form-field [config]="formConfig.currentMedication"></form-field>

  </div>

  <div
    data-testid="post-measurement-form-SYMPTOM_HOLTER"
    *ngIf="showPostMeasurementFields"
  >

    <ng-container *ngIf="showPreMeasurementFields">
      <hr/>
      <form-header [label]="formConfig.eventsDuringMeasurementLabel"></form-header>
    </ng-container>

    <div *ngIf="isEForm && isHome">
      <form-field [config]="formConfig.measurementStartTime"></form-field>
      <hr/>
    </div>


    <div class="mb-2" *ngFor="let symptom of formConfig.ecgSymptoms.model.symptoms; let symptomIndex = index">
      <app-ecg-symptom
        [data-testid]="formConfig.ecgSymptoms.property + '-' + symptomIndex"
        [symptom]="symptom"
        (symptomChange)="
        formConfig.ecgSymptoms.propertyChangeListener?.onPropertyChange(
          formConfig.ecgSymptoms.property,
          formConfig.ecgSymptoms.model.symptoms
        )
      "
        [symptomNumber]="symptomIndex + 1"
      ></app-ecg-symptom>
    </div>
    <div
      *ngIf="!!formConfig.ecgSymptoms.invalidLabel"
      [hidden]="formConfig.ecgSymptoms.isValid() !== false"
      class="form-field-invalid-feedback"
    >
      {{ formConfig.ecgSymptoms.invalidLabel | localize }}
    </div>

    <hr/>

    <form-field [config]="formConfig.patientFeedback"></form-field>

    <hr/>

    <form-field [config]="formConfig.nurseComments"></form-field>

  </div>
</div>
