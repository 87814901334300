<div
  data-testid="eform-non-home-header"
  *ngIf="!isHomeMeasurement"
>
  <h1 class="mb-3">{{ "eFormHeaderTitle" | localize }}</h1>
  <div>
    <p>{{ "eFormHeaderText_paragraph1" | localize }}</p>
    <p>{{ "eFormHeaderText_paragraph2" | localize }}</p>
  </div>
</div>

<div
  data-testid="eform-home-header"
  *ngIf="isHomeMeasurement"
>
  <h1 class="mb-3">{{ "homeEFormHeaderTitle" | localize }}</h1>
  <div>
    <p>{{ "homeEFormHeaderText_paragraph1" | localize }}</p>
    <p>{{ "homeEFormHeaderText_paragraph2" | localize }}</p>
  </div>
</div>
