<div class="container">
  <loading-indicator *ngIf="printFormData === undefined"></loading-indicator>
  <form class="form-striped" *ngIf="printFormData !== undefined">
    <form-field [config]="formConfig.type"></form-field>
    <form-field [config]="formConfig.initiatedTime"></form-field>
    <form-field *ngIf="!printFormData?.isHome" [config]="formConfig.name"></form-field>
    <form-field *ngIf="!printFormData?.isHome" [config]="formConfig.ssn"></form-field>
    <form-field [config]="formConfig.code"></form-field>
    <form-field [config]="formConfig.uniqueId"></form-field>
    <form-field [config]="formConfig.language"></form-field>
    <div class="row">
      <action-button
        [buttonLabel]="getPrintButtonLabel()"
        actionInProgressLabel="printOrderFormButtonInProgressLabel"
        (buttonClick)="printFormButtonClick()"
        [testId]="'submit-button'">
      </action-button>

      <div *ngIf="feature395Released" >
        <button
          *ngIf="printFormData?.isHome"
          class="btn btn-primary ml-2"
          data-testid="download-home-measurement-instructions-button"
          (click)="downloadHomeMeasurementInstructions()"
        >
          {{ "downloadHomeMeasurementInstructionsButtonLabel" | localize }}
        </button>
      </div>

      <div *ngIf="feature373Released">
        <button
          *ngIf="printFormData?.isHome"
          class="btn btn-primary ml-2"
          data-testid="copy-home-measurement-instructions-button"
          (click)="copyHomeMeasurementInstructions()"
        >
          {{ "copyHomeMeasurementInstructionsButtonLabel" | localize }}
        </button>
      </div>


      <span data-testid="submit-error-message" *ngIf="!!errorMessage" class="alert alert-danger ml-2">
        {{ errorMessage | localize }}
      </span>
    </div>


  </form>
</div>
