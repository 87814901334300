
<div
  data-testid="eform-non-home-footer"
  *ngIf="!isHomeMeasurement"
>
  <h3 class="mb-3">{{ "eFormFooterTitle" | localize }}</h3>
  <p>{{ "eFormFooterText_paragraph1" | localize }}</p>
</div>

<div
  data-testid="eform-home-footer"
  *ngIf="isHomeMeasurement"
>
  <h3 class="mb-3">{{ "homeEFormFooterTitle" | localize }}</h3>
  <p>{{ "homeEFormFooterText_paragraph1" | localize }}</p>
</div>
