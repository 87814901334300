import {Component, Input, OnInit} from "@angular/core";
import {MeasurementType} from "../../../measurement-models";
import {UploadFormConfig} from "../../../upload/upload-form-config";
import {HomeMeasurementStatus} from "../../../list/found-measurement";
import {shouldShowPreMeasurementFields} from "./should-show-pre-measurement-fields";
import {shouldShowPostMeasurementFields} from "./should-show-post-measurement-fields";
import {showPostMeasurementTitle} from "./show-post-measurement-title";

@Component({
  selector: "e-form-patient-data",
  templateUrl: "./e-form-patient-data.component.html"
})
export class EFormPatientDataComponent implements OnInit {

  @Input() type!: MeasurementType;
  @Input() formConfig!: UploadFormConfig;
  @Input() homeStatus: HomeMeasurementStatus | undefined;

  showPostMeasurementFields!: boolean;
  showPreMeasurementFields!: boolean;

  ngOnInit(): void {
    this.showPostMeasurementFields = shouldShowPostMeasurementFields(this.homeStatus);
    this.showPreMeasurementFields = shouldShowPreMeasurementFields(this.homeStatus);
  }

  showPostMeasurementTitle() {
    return showPostMeasurementTitle(this.homeStatus);
  }
}
