import {Component, Input, OnInit} from "@angular/core";
import {FoundMeasurement, HomeMeasurementStatus} from "../../found-measurement";
import {AuthenticatedUser, UserRole} from "../../../authentication/authenticated-user";
import {AuthenticationService} from "../../../authentication/authentication.service";
import {NavigationRoute} from "../../../../tools/navigation/navigation-route";
import {RouteNavigator} from "../../../../tools/navigation/route-navigator.service";

@Component({
  selector: "app-upload-initiated-button",
  templateUrl: "./upload-initiated-button.component.html",
})
export class UploadInitiatedButtonComponent implements OnInit {

  @Input() foundMeasurement!: FoundMeasurement;

  user!: AuthenticatedUser;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly routeNavigator: RouteNavigator,
  ) {
  }

  ngOnInit() {
    this.user = this.authenticationService.getCurrentAuthenticatedUser()!;
  }

  private readonly allowedHomeStatuses: HomeMeasurementStatus[] = [
    "MAILED_TO_PATIENT",
    "RETURNED_FROM_PATIENT"
  ];

  canUploadMeasurement() {
    const allowedRoles: UserRole[] = this.foundMeasurement.isHome
      ? ["DATA_OFFICER", "HMO"]
      : ["NURSE", "DATA_OFFICER"];

    return this.foundMeasurement.status === "INITIATED"
      && allowedRoles.includes(this.user.role)
      && !this.foundMeasurement.deleted
      && (this.foundMeasurement.homeStatus === undefined || this.allowedHomeStatuses.includes(this.foundMeasurement.homeStatus));
  }

  uploadInitiated() {
    return this.routeNavigator.navigateToPath([NavigationRoute.UPLOAD, this.foundMeasurement.id.toString()]);
  }
}
