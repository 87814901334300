import {MeasurementStatus, measurementStatusLocalizationKeys} from "../found-measurement";
import {UserRole} from "../../authentication/authenticated-user";
import {LocalizationStrings} from "../../../i18n/i18n-model";
import {ReleaseToggleState} from "../../../tools/release-toggles/release-toggle-state";

export function getStatusLocalizationKey(
  {
    status,
    role,
    isOrdered,
    isHome
  }:
  {
    status: MeasurementStatus;
    role: UserRole;
    isOrdered: boolean;
    isHome: boolean;
  }
): keyof LocalizationStrings | undefined {

  if (
    status === "INITIATED"
    && role === "NURSE"
    && (isOrdered || isHome)
    && ReleaseToggleState.getInstance().isReleased("ORDERED_STATUS_IN_NURSE_LIST_370")
  ) {
    return "nurseMeasurementStatusOrdered";
  }

  return measurementStatusLocalizationKeys
    .get(status)
    ?.get(role);
}
