<div *ngIf="shouldShowActionButtons()">
  <action-button
    *ngIf="shouldShowAssignTechnicianButton()"
    buttonType="button"
    buttonClass="btn btn-sm btn-primary mr-1 mb-1"
    [buttonLabel]="'measurementsGridAssignTechnicianButton'"
    [actionInProgressLabel]="'measurementsGridAssignTechnicianInProgress'"
    [actionInProgress]="item.isAssignTechnicianInProgress"
    [testId]="'assign-technician-button'"
    (buttonClick)="onAssignTechnicianClick()"></action-button>

  <button *ngIf="isAssignable()" type="button"
          data-testId="assign-doctor-button"
          [classList]="getAssignButtonColorClass() + ' btn btn-sm mr-1 mb-1'"
          (click)="onAssignClick()">
    {{ getAssignButtonLabel() | localize }}
  </button>

  <button *ngIf="canUploadReport()" type="button" class="btn btn-sm btn-primary mr-1 mb-1"
          (click)="onUploadReportClick()">
    {{ "measurementsGridButtonLabelUploadReport" | localize }}
  </button>

  <button *ngIf="shouldShowCopyButton()" type="button" class="btn btn-sm btn-secondary mr-1 mb-1"
          (click)="onCopyClick()">
    {{ "measurementsGridButtonLabelCopy" | localize }}
  </button>

  <button
    *ngIf="shouldShowOpenAnalysisButton()"
    type="button"
    class="btn btn-sm btn-secondary mr-4 mb-1"
    data-testid="open-analysis-button"
    (click)="onOpenAnalysisClick()">
    {{ "measurementsGridButtonLabelOpenAnalysis" | localize }}
  </button>

  <action-button
    [testId]="'close-analysis-button'"
    *ngIf="shouldShowCloseAnalysisButton()"
    buttonType="button"
    buttonClass="btn btn-sm btn-primary mr-4 mb-1"
    [buttonLabel]="'measurementsGridButtonLabelCloseAnalysis' | localize"
    [actionInProgressLabel]="'measurementsGridButtonLabelCloseAnalysisInProgress' | localize"
    [actionInProgress]="this.item.isCloseAnalysisInProgress"
    (buttonClick)="onCloseAnalysisClick()"></action-button>

  <action-button
    *ngIf="shouldShowDataOfficerDeleteButton()"
    buttonType="button"
    buttonClass="btn btn-sm btn-primary mr-4 mb-1"
    [buttonLabel]="'measurementsGridButtonLabelDeleteMeasurement' | localize"
    [actionInProgressLabel]="'measurementsGridButtonLabelDeleteMeasurementInProgress' | localize"
    [actionInProgress]="item.isDeleteMeasurementInProgress"
    [testId]="'data-officer-delete-measurement-button'"
    (buttonClick)="onDeleteMeasurementClick()"></action-button>

  <button
    *ngIf="shouldShowCommentsButton()"
    type="button"
    [ngClass]="'btn btn-sm mr-4 mb-1 ' + getCommentsButtonType()"
    data-testid="show-comments-button"
    (click)="onShowCommentsClick()">{{ getCommentButtonLabel('measurementsGridButtonLabelShowComments' | localize, item.foundMeasurement.commentCount) }}
  </button>

  <app-return-study
    *ngIf="shouldShowReturnStudy()"
    [measurementId]="item.foundMeasurement.id"
    (returnedStudy)="onReturnStudy($event)"></app-return-study>

  <action-button
    *ngIf="canRejectStudy()"
    buttonType="button"
    buttonClass="btn btn-sm btn-danger mr-4 mb-1"
    [testId]="'reject-measurement-button'"
    [buttonLabel]="'measurementsGridButtonLabelRejectStudy'"
    [actionInProgressLabel]="'measurementsGridButtonLabelRejectStudyInProgress'"
    [actionInProgress]="item.isRejectStudyInProgress"
    (buttonClick)="onRejectStudyClick()"></action-button>

  <action-button
    *ngIf="shouldShowRestoreMeasurementButton()"
    buttonType="button"
    buttonClass="btn btn-sm btn-secondary mr-4 mb-1"
    [testId]="'restore-measurement-button'"
    [buttonLabel]="'measurementsGridButtonLabelRestoreMeasurement'"
    [actionInProgressLabel]="'measurementsGridButtonLabelRestoreMeasurementInProgress'"
    [actionInProgress]="item.isRestoreMeasurementInProgress"
    (buttonClick)="restoreMeasurement()"></action-button>

  <app-reassign-doctor-button
    [item]="item"
    testId="reassign-doctor-button"
    (measurementChanged)="measurementChanged.emit()"
  ></app-reassign-doctor-button>

  <button
    *ngIf="canMailDevice()"
    type="button"
    data-testid="mail-device-button"
    (click)="mailDevice()"
    class="btn btn-sm btn-primary mr-4 mb-1"
  >{{ 'measurementsGridButtonLabelMailDevice' | localize }}
  </button>

  <app-mailing-info
    *ngIf="shouldShowMailingInfo()"
    [measurementId]="item.foundMeasurement.id"
  ></app-mailing-info>

</div>


<div *ngIf="!shouldShowActionButtons()">


  <report-upload
    *ngIf="item.showReportUpload"
    [item]="item.foundMeasurement"
    (fileUploaded)="onReportFileUploaded($event)"
    (fileUploadCanceled)="onReportFileUploadCancelled()">
  </report-upload>
</div>

<app-upload-initiated-button
  *ngIf="shouldShowUploadInitiatedButton()"
  data-testid="upload-initiated-button-actions-column"
  [foundMeasurement]="item.foundMeasurement"
>
</app-upload-initiated-button>

<ng-template #templateComments>
  <div class="modal-body text-center">
    <app-comments
      (commentsCountChanged)="onCommentsCountChanged($event)"
      [measurementId]="item.foundMeasurement.id"
      [code]="item.foundMeasurement.code"
      [hospitalName]="item.foundMeasurement.hospitalName"
    >

    </app-comments>
    <button type="button" class="btn btn-primary" (click)="modalRefComments?.hide()">
      {{ "modalResponseClose" | localize }}
    </button>
  </div>
</ng-template>

<ng-template #templateConfirmReject>
  <div class="modal-body text-center">
    <p>
      {{ "modalPromptAreYouSure" | localize }}
    </p>
    <button type="button" class="btn btn-default" (click)="rejectStudy()">
      {{ "modalResponseYes" | localize }}
    </button>
    <button type="button" class="btn btn-primary" (click)="modalRefRejectConfirm?.hide()">
      {{ "modalResponseNo" | localize }}
    </button>
  </div>
</ng-template>
