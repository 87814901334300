import {Component, OnInit} from "@angular/core";
import {NavigationRoute} from "../../../tools/navigation/navigation-route";
import {RouteNavigator} from "../../../tools/navigation/route-navigator.service";
import {EFormService} from "../e-form.service";
import {Notifications, NotificationType} from "../../../tools/notifications/notifications";
import {environment} from "../../../../environments/environment";
import {RecaptchaService} from "./recaptcha.service";
import {ReleaseToggleState} from "../../../tools/release-toggles/release-toggle-state";
import {LocalizationStrings} from "../../../i18n/i18n-model";
import {getTitleFromDomain} from "./get-title-from-domain";
import {getEmailAddressFromDomain} from "./email-from-domain";

interface AvailableLanguage {
  code: string;
  name: string;
}

@Component({
  selector: "eform-landing-page",
  templateUrl: "./e-form-landing-page.component.html",
})
export class EFormLandingPageComponent implements OnInit {

  isHomeMeasurement = environment.homeHolter;

  code: String | undefined;
  codeInvalid?: boolean;

  captchaToken: string | undefined;

  languageFeatureReleased = false;
  newDesignReleased = false;

  availableLanguages: AvailableLanguage[] = [
    {code: "en", name: "English"},
    {code: "fi", name: "Suomi"},
    {code: "sv", name: "Svenska"},
  ];
  selectedLanguage = "";

  domainName = window.location.hostname;

  constructor(private readonly eFormService: EFormService,
    private readonly navigator: RouteNavigator,
    private readonly notifications: Notifications,
    private readonly recaptchaService: RecaptchaService
  ) {
  }

  ngOnInit(): void {
    this.languageFeatureReleased = ReleaseToggleState
      .getInstance()
      .isReleased("E_FORM_LANGUAGE_SELECTOR_303");
    this.newDesignReleased = ReleaseToggleState
      .getInstance()
      .isReleased("NEW_E_FORM_LANDING_PAGE_DESIGN_304");
    this.setLanguage(window.navigator.language);
    this.description = this.isHomeMeasurement ? "homeHolterLandingPageDescription" : "eFormLandingPageDescription";
  }

  setLanguage(browserLang: string) {
    const language = this.selectAvailableLanguage(browserLang);
    this.selectedLanguage = language;
    environment.language = language;
  }

  private selectAvailableLanguage(browserLang: string) {
    const idx = browserLang.indexOf("-");
    const language = (idx === -1) ? browserLang : browserLang.substring(0, idx);
    return this.availableLanguages
      .map(availableLanguage => availableLanguage.code)
      .find(code => code === language)
      || "en";
  }

  private readonly codeRegex = new RegExp("^[A-Z]{6}$");

  description!: keyof LocalizationStrings;


  validate() {
    this.codeInvalid = this.code === undefined || !this.codeRegex.test(this.code.toString());
  }

  onCodeChanged(newCode: string) {
    // noinspection JSPrimitiveTypeWrapperUsage: new String() is needed here so angular picks up the change
    this.code = new String(newCode.toUpperCase().replaceAll(/[^A-Z]/g, ""));
    this.validate();
  }

  onSubmitClick() {
    this.validate();
    if (!this.codeInvalid) {
      this.eFormService.getEFormId({
        eFormCode: this.code!.toString(),
        captchaToken: this.captchaToken!
      }).subscribe(
        (eFormIdResponse) => {
          this.navigator.navigateToPath([NavigationRoute.EFORM_FORM_PAGE, eFormIdResponse.id]);
        },
        (_) => {
          this.recaptchaService.reset();
          this.notifications.addNotification(NotificationType.ERROR, "eFormLandingPageError");
        });

    }
  }

  resolved(token: string) {
    this.captchaToken = token;
  }

  onError($event: []) {
    this.captchaToken = undefined;
  }

  getLanguageNgClass(language: string) {
    return {
      "disabled": this.selectedLanguage === language
    };
  }

  protected readonly getTitleFromDomain = getTitleFromDomain;
  protected readonly getEmailAddressFromDomain = getEmailAddressFromDomain;
}
