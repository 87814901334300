import {Component, OnInit} from "@angular/core";
import {PrintOrderService} from "./print-order.service";
import {MeasurementIdProvider} from "../analize/measurement-id-provider";
import {ActivatedRoute} from "@angular/router";
import {FormConfigBase} from "../../tools/form/form-config-base";
import {PropertyChangeListener} from "../../tools/form/form-field-config";
import {isExisting} from "../validations";
import {FinnishSSN} from "finnish-ssn";
import {OrderPdfGeneratorService} from "./order-pdf-generator.service";
import {NavigationRoute} from "../../tools/navigation/navigation-route";
import {RouteNavigator} from "../../tools/navigation/route-navigator.service";
import {formatDateTime} from "../../tools/date-time-utils";
import {localizationKey} from "../../i18n/i18n-model";
import {Notifications, NotificationType} from "../../tools/notifications/notifications";
import {getMeasurementTypeLocalizationKey, MeasurementType} from "../measurement-models";
import {measurementTypeOptions} from "../measurement-type-select-options";
import {PrintOrderData} from "./print-order-data";
import {FormValidator} from "../../tools/form/form-validator";
import {ReleaseToggleState} from "../../tools/release-toggles/release-toggle-state";
import {I18nService} from "../../i18n/i18n.service";

@Component({
  selector: "app-print-order-form",
  templateUrl: "./print-order-form.component.html"
})
export class PrintOrderFormComponent implements OnInit, PropertyChangeListener<PrintOrderModel> {

  printOrderModel?: PrintOrderModel;

  formConfig?: PrintOrderFormConfig;

  private printOrderFormValidator?: PrintOrderFormValidator;
  private typeLabels = new Map<MeasurementType, string>()
    .set("SLEEP", "Yöpolygrafia / Nattpolygrafi")
    .set("HOLTER_ECG", "Holter-EKG")
    .set("SYMPTOM_HOLTER", "Oire-Holter / Symtom-Holter")
    .set("ECG_ATRIAL_FIBRILLATION", "Eteisvärinän seulonta / Förmaksflimmerscreening")
    .set("BLOOD_PRESSURE", "24 h verenpaineseuranta / 24-timmars blodtryck registrering");
  private printOrderData?: PrintOrderData;

  errorMessage?: string;

  feature395Released = ReleaseToggleState.getInstance().isReleased("DOWNLOAD_HOME_MEASUREMENT_INSTRUCTIONS_395");

  constructor(
    private readonly printOrderService: PrintOrderService,
    private readonly measurementIdProvider: MeasurementIdProvider,
    private readonly route: ActivatedRoute,
    private readonly orderPdfGenerator: OrderPdfGeneratorService,
    private readonly navigator: RouteNavigator,
    private readonly notifications: Notifications,
    private readonly i18nService: I18nService
  ) {
  }

  onPropertyChange(property: keyof PrintOrderModel, newValue: any): void {
    this.printOrderModel! [property] = newValue;
    this.printOrderFormValidator!.updatePropertyValidation(property);

    this.errorMessage = this.printOrderFormValidator!.isFormValid() ? "" : "printOrderFormValidationErrorDefault";
  }

  ngOnInit(): void {
    const measurementId = this.measurementIdProvider.getId(this.route)!;
    this.printOrderService.getPrintOrderData(measurementId).subscribe(
      (printOrderData) => {
        if (this.operatorInformationNotAvailable(printOrderData)) {
          this.notifications.addNotification(NotificationType.WARNING, localizationKey("printOrderFormNoOperatorInformation"));
        }

        this.printOrderData = printOrderData;
        this.printOrderModel = {
          code: printOrderData.code,
          type: printOrderData.type,
          orderingHospital: printOrderData.orderingHospital,
          operatorHospital: printOrderData.operatorHospital,
          initiatedTime: printOrderData.initiatedTime,
          uniqueId: printOrderData.uniqueId
        } as PrintOrderModel;
        this.printOrderFormValidator = new PrintOrderFormValidator(this.printOrderModel);
        this.formConfig = new PrintOrderFormConfig(
          this.printOrderModel,
          this,
          (property) => this.printOrderFormValidator!.isPropertyValid(property)
        );
      },
      () => {
        this.notifications.addNotification(NotificationType.ERROR, localizationKey("printOrderFormErrorGettingData"));
      }
    );
  }

  onPrintButtonClick(download: boolean) {
    const printOrderFormValidator = this.printOrderFormValidator!;
    printOrderFormValidator.updateAllValidations();

    if (!printOrderFormValidator.isFormValid()) {
      this.errorMessage = "printOrderFormValidationErrorDefault";
      return;
    }

    const printOrderModel = this.printOrderModel!;
    const printOrderData = this.printOrderData!;
    this.orderPdfGenerator.generate({
      type: this.getTypeLabel()!,
      code: printOrderModel.code,
      initiatedTime: formatDateTime(printOrderModel.initiatedTime),
      operatorHospital: printOrderData.operatorPublicName!,
      orderingHospital: printOrderData.orderingHospital,
      uniqueId: printOrderModel.uniqueId,
      ssn: printOrderModel.ssn,
      name: printOrderModel.name,
      pdf: printOrderData.pdf,
      operatorAppointmentDescription: printOrderData.operatorAppointmentDescription,
      address: printOrderData.address,
      city: printOrderData.city,
      postalCode: printOrderData.postalCode,
      fileName: download ? this.createFileName() : undefined
    }).subscribe(
      () => this.navigator.navigateTo(NavigationRoute.LIST)
    );
  }

  getTypeLabel() {
    return this.typeLabels.get(this.printOrderModel!.type!);
  }

  private operatorInformationNotAvailable(printOrderData: PrintOrderData) {
    return [
      printOrderData.operatorPublicName,
      printOrderData.address,
      printOrderData.city,
      printOrderData.postalCode,
      printOrderData.operatorAppointmentDescription
    ]
      .some((prop) => prop === null || prop === undefined);
  }

  private createFileName() {
    const prefix = this.i18nService.getLocalizedString("homeMeasurementInstructionsFilePrefix");
    const measurementTypeString = this.i18nService.getLocalizedString(getMeasurementTypeLocalizationKey(this.printOrderData!.type));
    return `${prefix} ${measurementTypeString} ${this.printOrderData!.code}.pdf`;
  }
}

class PrintOrderFormValidator extends FormValidator<PrintOrderModel> {

  constructor(hospitalInformation: PrintOrderModel) {
    super(hospitalInformation, new Map<keyof PrintOrderModel, (value: any) => boolean>()
      .set("ssn", (value: string) => FinnishSSN.validate(value))
      .set("name", (value: string) => isExisting(value)));
  }

}

class PrintOrderFormConfig extends FormConfigBase<PrintOrderModel> {

  readonly type = this.createField({
    property: "type",
    label: "printOrderFormType",
    type: "dropdown",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelName",
    readOnly: true,
    selectionOptions: measurementTypeOptions
  });

  readonly orderingHospital = this.createField({
    property: "orderingHospital",
    label: "printOrderFormLabelOrderingHospital",
    type: "text",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelName",
    readOnly: true
  });

  readonly operatorHospital = this.createField({
    property: "operatorHospital",
    label: "printOrderFormLabelOperatorHospital",
    type: "text",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelName",
    readOnly: true
  });

  readonly initiatedTime = this.createField({
    property: "initiatedTime",
    label: "printOrderFormLabelInitiatedTime",
    type: "localdate",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelName",
    readOnly: true
  });

  readonly name = this.createField({
    property: "name",
    label: "printOrderFormLabelName",
    type: "text",
    required: true,
    invalidLabel: "printOrderFormInvalidLabelName"
  });

  readonly ssn = this.createField({
    property: "ssn",
    label: "printOrderFormLabelSsn",
    type: "text",
    required: true,
    invalidLabel: "printOrderFormInvalidLabelSsn"
  });

  readonly code = this.createField({
    property: "code",
    label: "printOrderFormLabelCode",
    type: "text",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelSsn",
    readOnly: true
  });

  readonly uniqueId = this.createField({
    property: "uniqueId",
    label: "printOrderFormLabelUniqueId",
    type: "text",
    required: false,
    invalidLabel: "printOrderFormInvalidLabelSsn",
    readOnly: true
  });

}


interface PrintOrderModel {
  type?: MeasurementType;
  orderingHospital: string;
  operatorHospital: string;
  initiatedTime: string;
  name: string;
  ssn: string;
  code: string;
  uniqueId: string;
}

