import {LocalizationStrings} from "../i18n/i18n-model";
import {NurseMeasurementType} from "./initiate-measurement/initiate-measurement-data";
import {getNurseMeasurementType} from "./get-nurse-measurement-type";

export const ALL_MEASUREMENT_TYPES = ["SLEEP", "HOLTER_ECG", "ECG_ATRIAL_FIBRILLATION", "SYMPTOM_HOLTER", "BLOOD_PRESSURE"] as const;
type MeasurementTypeTuple = typeof ALL_MEASUREMENT_TYPES;
export type MeasurementType = MeasurementTypeTuple[number]; // union type

export type ReportType = "MEASUREMENT_REPORT" | "MEASUREMENT_SECONDARY_REPORT" | "MEASUREMENT_COMBINED_REPORT";

const holterTimedTypeLocalizationKeys: ReadonlyMap<number, keyof LocalizationStrings> = new Map<number, keyof LocalizationStrings>()
  .set(1, "holterTimedTypeOfDurationDays1")
  .set(2, "holterTimedTypeOfDurationDays2")
  .set(3, "holterTimedTypeOfDurationDays3")
  .set(4, "holterTimedTypeOfDurationDays4")
  .set(5, "holterTimedTypeOfDurationDays5")
  .set(6, "holterTimedTypeOfDurationDays6")
  .set(7, "holterTimedTypeOfDurationDays7");

const homeHolterDurationTypeLocalizationKeys: ReadonlyMap<number, keyof LocalizationStrings> = new Map<number, keyof LocalizationStrings>()
  .set(1, "homeHolterDuration1dTypeLabel")
  .set(2, "homeHolterDuration2dTypeLabel")
  .set(3, "homeHolterDuration3dTypeLabel")
  .set(4, "homeHolterDuration4dTypeLabel")
  .set(5, "homeHolterDuration5dTypeLabel")
  .set(6, "homeHolterDuration6dTypeLabel")
  .set(7, "homeHolterDuration7dTypeLabel");

const measurementTypeLocalizationKeys: ReadonlyMap<NurseMeasurementType, keyof LocalizationStrings> =
  new Map<NurseMeasurementType, keyof LocalizationStrings>()
    .set("SLEEP", "enumMeasurementTypeSleep")
    .set("HOLTER_ECG", "enumMeasurementTypeHolterEcg")
    .set("SYMPTOM_HOLTER", "enumMeasurementTypeSymptomHolter")
    .set("ECG_ATRIAL_FIBRILLATION", "enumMeasurementTypeEcgAtrialFibrillation")
    .set("BLOOD_PRESSURE", "enumMeasurementTypeBloodPressure")
    .set("HOME_SLEEP", "homeSleepTypeLabel")
    .set("HOME_HOLTER_24H", "homeHolterDuration1dTypeLabel")
    .set("HOME_HOLTER_48H", "homeHolterDuration2dTypeLabel")
    .set("HOME_HOLTER_7D", "homeHolterDuration7dTypeLabel")
    .set("HOME_SYMPTOM_HOLTER", "homeSymptomHolterTypeLabel")
    .set("HOME_ECG_ATRIAL_FIBRILLATION", "homeEcgAtrialFibrillationTypeLabel")
    .set("HOME_BLOOD_PRESSURE", "homeBloodPressureTypeLabel");

const homeMeasurementTypeLocalizationKeys: ReadonlyMap<MeasurementType, keyof LocalizationStrings> = new Map<
  MeasurementType,
  keyof LocalizationStrings
>()
  .set("SLEEP", "homeSleepTypeLabel")
  .set("HOLTER_ECG", "homeHolterTypeLabel")
  .set("SYMPTOM_HOLTER", "homeSymptomHolterTypeLabel")
  .set("ECG_ATRIAL_FIBRILLATION", "homeEcgAtrialFibrillationTypeLabel")
  .set("BLOOD_PRESSURE", "homeBloodPressureTypeLabel");


export function getMeasurementTypeDisplayLocalizationKey(
  {
    type,
    isHome,
    actualDurationDays,
    specifiedDurationDays
  }: {
    type: MeasurementType;
    isHome: boolean;
    actualDurationDays?: number;
    specifiedDurationDays?: number;
  }
): keyof LocalizationStrings {

  const nurseMeasurementType = getNurseMeasurementType(
    type,
    isHome,
    {specifiedHolterDuration: specifiedDurationDays}
  );

  return type !== "HOLTER_ECG" || actualDurationDays === undefined
    ? getMeasurementTypeLocalizationKey(nurseMeasurementType)
    : isHome
      ? homeHolterDurationTypeLocalizationKeys.get(actualDurationDays)!
      : holterTimedTypeLocalizationKeys.get(actualDurationDays)!;
}

export function getMeasurementTypeLocalizationKey(type: NurseMeasurementType): keyof LocalizationStrings {
  return measurementTypeLocalizationKeys.get(type) || "enumMeasurementTypeSleep";
}

export function isAnalysisCloseable(type: MeasurementType): boolean {
  return isEcgMeasurement(type) || type === "BLOOD_PRESSURE";
}

export function isEcgMeasurement(type: MeasurementType): boolean {
  const ecgMeasurementTypes: MeasurementType[] = ["SYMPTOM_HOLTER", "HOLTER_ECG", "ECG_ATRIAL_FIBRILLATION"];

  return ecgMeasurementTypes.includes(type);
}

export function isBloodPressureMeasurement(type: MeasurementType): boolean {
  return type === "BLOOD_PRESSURE";
}
