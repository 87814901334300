import {Component, Input} from "@angular/core";

@Component({
  selector: "app-eform-footer",
  templateUrl: "./eform-footer.component.html",
})
export class EformFooterComponent {
  @Input()
  isHomeMeasurement!: boolean;
}
