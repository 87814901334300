import {FormValidator} from "../../../../tools/form/form-validator";
import {
  isRequiredDigitsStringOfLength,
  isRequiredFormatStringOfLength,
  isRequiredTextOfMaxLength
} from "../../../validations";
import {MAX_CHARACTERS_IN_TEXT} from "../../../utils";
import {MailingInfoFormModel} from "./e-form-mailing-info.component";

const phoneRegex = /^\+?(\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

export class MailingInfoValidator extends FormValidator<MailingInfoFormModel> {
  constructor(mailingInfoModel: MailingInfoFormModel) {
    super(mailingInfoModel, new Map([
      ["name", () => isRequiredTextOfMaxLength(MAX_CHARACTERS_IN_TEXT, mailingInfoModel.name)],
      ["phoneNumber", () => isRequiredFormatStringOfLength(30, phoneRegex, mailingInfoModel.phoneNumber)],
      ["streetAddress", () => isRequiredTextOfMaxLength(50, mailingInfoModel.streetAddress)],
      ["postalArea", () => isRequiredFormatStringOfLength(30, /^\p{L}+(?:[- ]\p{L}+)*$/u, mailingInfoModel.postalArea)],
      ["postalCode", () => isRequiredDigitsStringOfLength(5, mailingInfoModel.postalCode)],
    ]));
  }
}
