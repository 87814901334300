import {HomeMeasurementStatus} from "../../../list/found-measurement";

const allowedStatuses: HomeMeasurementStatus[] = [
  "MAILED_TO_PATIENT",
  "RETURNED_FROM_PATIENT",
];

export function showPostMeasurementTitle(homeStatus: HomeMeasurementStatus | undefined): boolean {
  return homeStatus === undefined || allowedStatuses.includes(homeStatus);
}
