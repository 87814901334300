import {Component, OnInit} from "@angular/core";
import {MeasurementType} from "../../measurement-models";
import {EFormService} from "../e-form.service";
import {EFormIdProvider} from "../e-form-id-provider.service";
import {ActivatedRoute} from "@angular/router";
import {UploadFormConfig} from "../../upload/upload-form-config";
import {emptyUploadFormModel, UploadFormModel} from "../../upload/upload-form-model";
import {PropertyChangeListener} from "../../../tools/form/form-field-config";
import {mapToPatientData} from "../../upload/upload-data-mapper";
import {EFormData} from "../e-form-model";
import {Notifications, NotificationType} from "../../../tools/notifications/notifications";
import {localizationKey} from "../../../i18n/i18n-model";
import {debounceTime} from "rxjs/operators";
import {Subject} from "rxjs";
import {addEmptyEcgSymptoms} from "../../utils";
import {environment} from "../../../../environments/environment";
import {isMailingInfoSet} from "./is-mailing-info-set";
import {HomeMeasurementStatus} from "../../list/found-measurement";
import {HomeEFormValidator} from "./home-e-form-validator";
import {measurementTypeOptions} from "../../measurement-type-select-options";

export const eFormConfiguration = {
  debounceTimeMillis: 1000
};

@Component({
  selector: "eform-form",
  templateUrl: "./e-form-form.component.html",
})
export class EFormFormComponent implements OnInit, PropertyChangeListener<UploadFormModel> {

  type?: MeasurementType;
  formConfig?: UploadFormConfig;

  isHomeMeasurement?: boolean;
  homeStatus: HomeMeasurementStatus | undefined = undefined;

  mailingInfoSet = false;
  private readonly saveForm = new Subject();

  readonly uploadFormModel: UploadFormModel = emptyUploadFormModel();
  eFormId?: string;

  eFormLoaded = false;

  homeEFormValidator: HomeEFormValidator | undefined;

  constructor(
    private readonly eFormService: EFormService,
    private readonly eFormCodeProvider: EFormIdProvider,
    private readonly route: ActivatedRoute,
    private readonly notifications: Notifications
  ) {
  }


  ngOnInit(): void {
    this.eFormId = this.eFormCodeProvider.getEFormId(this.route);
    this.eFormService.getEForm(this.eFormId!)
      .subscribe((eform) => {
        environment.language = eform.language;
        this.type = eform.type;

        this.isHomeMeasurement = eform.homeStatus !== undefined;
        this.homeStatus = eform.homeStatus;

        this.mailingInfoSet = isMailingInfoSet(eform.homeStatus);
        const data = eform.data;
        data.patientData = addEmptyEcgSymptoms(eform.data.patientData);
        this.mapEFormDataToUploadFormModel(data);
        this.createFormConfig(this.isHomeMeasurement);
        this.eFormLoaded = true;
      }, () => {
        this.notifications.addNotification(NotificationType.ERROR, localizationKey("eFormGetFormError"));
      });

    this
      .saveForm
      .pipe(debounceTime(eFormConfiguration.debounceTimeMillis))
      .subscribe(_ => {
        this.eFormService
          .updateEForm(this.eFormId!, this.mapUploadFormModelToEFormData())
          .subscribe(() => {
            },
            () => {
              this.notifications.addNotification(NotificationType.WARNING, localizationKey("eFormUpdateFormError"));
            }
          );
      });
  }

  createFormConfig(isHomeMeasurement: boolean) {
    if (isHomeMeasurement) {
      this.homeEFormValidator = new HomeEFormValidator(this.uploadFormModel);
    }

    this.formConfig = new UploadFormConfig(
      this.uploadFormModel,
      this,
      (property) => this.homeEFormValidator?.isPropertyValid(property),
      false,
      false,
      measurementTypeOptions,
      false,
      isHomeMeasurement
    );
  }

  onPropertyChange(property: keyof UploadFormModel, newValue: any): void {
    (this.uploadFormModel as any)[property] = newValue;
    this.homeEFormValidator?.updatePropertyValidation(property);

    this.saveForm.next({});
  }

  private mapEFormDataToUploadFormModel(data: EFormData): void {
    Object.keys(data.patientData).forEach(property => {
      // @ts-ignore
      this.uploadFormModel[property] = data.patientData[property];
    });
    this.uploadFormModel.measurementStartTime = data.measurementStartTime;
  }

  private mapUploadFormModelToEFormData(): EFormData {
    return {
      patientData: mapToPatientData(this.uploadFormModel, "REMOVE_EMPTY_SYMPTOMS"),
      measurementStartTime: this.uploadFormModel.measurementStartTime
    };
  }
}


