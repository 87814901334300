<div *ngIf="homeStatus">
  <button
    data-testid="pre-measurement-collapse-button"
    class="btn btn-sm btn-secondary"
    (click)="showPreMeasurementFields=!showPreMeasurementFields"
  >
    <i *ngIf="!showPreMeasurementFields" class="fa fa-plus"></i>
    <i *ngIf="showPreMeasurementFields" class="fa fa-minus"></i>
  </button>
  <span class="ml-2 font-weight-bold"> {{ "homeMeasurementExpandPreFieldsLabel" | localize }}</span>
</div>

<form>
  <app-form-selector
    [type]="type!"
    [formConfig]="formConfig!"
    [showPostMeasurementFields]="false"
    [showPreMeasurementFields]="showPreMeasurementFields"
    [isHome]="homeStatus != undefined"
  ></app-form-selector>
</form>


<div class="mt-3">
  <button
    *ngIf="homeStatus==='RETURNED_FROM_PATIENT'"
    data-testid="post-measurement-collapse-button"
    class="btn btn-sm btn-secondary"
    (click)="showPostMeasurementFields=!showPostMeasurementFields"
  >
    <i *ngIf="!showPostMeasurementFields" class="fa fa-plus"></i>
    <i *ngIf="showPostMeasurementFields" class="fa fa-minus"></i>
  </button>
  <span
    *ngIf="showPostMeasurementTitle()"
    class="ml-2 font-weight-bold"> {{ "homeMeasurementExpandPostFieldsLabel" | localize }}</span>
</div>
<form>
  <app-form-selector
    [type]="type!"
    [formConfig]="formConfig!"
    [showPostMeasurementFields]="showPostMeasurementFields"
    [showPreMeasurementFields]="false"
    [isHome]="homeStatus != undefined"
  ></app-form-selector>
</form>
