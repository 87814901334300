<ng-container *ngIf="isFinishMeasuringButtonVisible()">
  <app-pre-confirm-action-button
    [buttonLabel]="'eFormFinishMeasuringButtonLabel' | localize"
    [actionInProgressLabel]="'eFormFinishMeasuringInProgressButtonLabel' | localize"
    [buttonType]="'button'"
    [confirmationLabel]="'eFormFinishMeasuringConfirmationButtonLabel' | localize"
    [buttonDisabled]="isFinishMeasuringButtonDisabled()"
    (buttonClick)="finishMeasuring()"
    [buttonState]="actionState"
    [testId]="'finish-home-measurement-button'">
  </app-pre-confirm-action-button>
</ng-container>
