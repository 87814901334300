import { Injectable } from "@angular/core";
import {ApiClient} from "../../../../tools/api-client";
import {Observable} from "rxjs";
import {TODO} from "../../../../tools/test/todo";


@Injectable()
export class EFormFinishMeasuringService {
  constructor(private readonly apiClient: ApiClient) {
  }

  finishMeasuring(eFormId: string): Observable<void> {
    return this.apiClient.post(`/api/eform/${eFormId}/finish-measuring`, {});
  }
}
