import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from "@angular/core";
import {emptyFoundMeasurement, FoundMeasurement} from "../found-measurement";
import {AuthenticatedUser, UserRole} from "../../authentication/authenticated-user";
import {localizationKey, LocalizationStrings} from "../../../i18n/i18n-model";
import {getMeasurementTypeDisplayLocalizationKey} from "../../measurement-models";
import {NavigationRoute} from "../../../tools/navigation/navigation-route";
import {RouteNavigator} from "../../../tools/navigation/route-navigator.service";
import {languageDefinitions} from "../../../tools/languages";
import {finalize} from "rxjs/operators";
import {Notifications, NotificationType} from "../../../tools/notifications/notifications";
import {ListItem} from "../list-item";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {DeleteMeasurementService} from "../delete-measurement/delete-measurement.service";
import {ReleaseToggleState} from "../../../tools/release-toggles/release-toggle-state";
import {I18nService} from "../../../i18n/i18n.service";
import {replaceAnchors} from "../../analize/ecg/generators/findings-generator/replace-anchors";
import {ColumnVisibility} from "../create-column-visibility";
import {AuthenticationService} from "../../authentication/authentication.service";
import {getStatusLocalizationKey} from "./get-status-localization-key";
import {getHomeStatusLocalizationKey} from "./get-home-status-localization-key";

export function emptyListItem(): ListItem {
  return {
    foundMeasurement: emptyFoundMeasurement(),
    isCloseAnalysisInProgress: false,
    showReportUpload: false,
    isItemDetailsVisible: false,
    isRejectStudyInProgress: false,
    isDeleteMeasurementInProgress: false,
    isAssignTechnicianInProgress: false,
    isRestoreMeasurementInProgress: false,
    isReassignInProgress: false
  };
}

export type ReportDownloadAction = "PRIMARY" | "SECONDARY";


@Component({
  selector: "app-list-item",
  templateUrl: "./list-item.component.html"
})
export class ListItemComponent implements OnInit {

  @Input() columnVisibility: ColumnVisibility = {} as ColumnVisibility;
  @Input() item: ListItem = emptyListItem();

  @Output() measurementChanged = new EventEmitter<void>();

  modalRefDeleteMeasurementConfirm: BsModalRef | undefined;
  @ViewChild("templateConfirmDeleteMeasurement") templateConfirmDeleteMeasurement!: TemplateRef<any>;

  user!: AuthenticatedUser;

  readonly languageFlags = Object.fromEntries(languageDefinitions
    .map(definition => [
      definition.value, definition.flag
    ]));

  constructor(
    private readonly routeNavigator: RouteNavigator,
    private readonly notifications: Notifications,
    private readonly modalService: BsModalService,
    private readonly deleteMeasurementService: DeleteMeasurementService,
    private readonly i18nService: I18nService,
    private readonly authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.item.selectedDoctor = this.item.foundMeasurement.doctor;
    this.user = this.authenticationService.getCurrentAuthenticatedUser()!;
  }

  canViewDetails() {
    return this.user.role === "DATA_OFFICER";
  }

  toggleItemDetailsVisibility() {
    this.item.isItemDetailsVisible = !this.item.isItemDetailsVisible;
  }

  getItemTypeDisplayText(): keyof LocalizationStrings {
    return getMeasurementTypeDisplayLocalizationKey({
      type: this.item.foundMeasurement.type,
      isHome: this.item.foundMeasurement.isHome,
      actualDurationDays: this.item.foundMeasurement.durationDays,
      specifiedDurationDays: this.item.foundMeasurement.specifiedHomeHolterDuration
    });
  }

  canViewAsyncJobIndicator() {
    return this.user.role === "DATA_OFFICER";
  }

  isJobPending() {
    return this.item.foundMeasurement.asyncJobStatus === "PENDING";
  }

  isJobFailed() {
    return this.item.foundMeasurement.asyncJobStatus === "FAILED";
  }

  hasJobSucceeded() {
    return this.item.foundMeasurement.asyncJobStatus === "SUCCESS";
  }


  canPrintForm() {
    return this.user.role === "NURSE" && this.item.foundMeasurement.status === "INITIATED";
  }

  technicianName() {
    if (this.item.foundMeasurement.technician === undefined) {
      return "";
    }

    return `${this.item.foundMeasurement.technician.firstName} ${this.item.foundMeasurement.technician.lastName}`;
  }

  canPrintOrder() {
    return this.item.foundMeasurement.status === "INITIATED" && this.user.role === "ORDERING_NURSE";
  }

  printForm() {
    return this.routeNavigator.navigateToPath([NavigationRoute.PRINT_FORM, this.item.foundMeasurement.id.toString()]);
  }

  printOrder() {
    return this.routeNavigator.navigateToPath([NavigationRoute.PRINT_ORDER_FORM, this.item.foundMeasurement.id.toString()]);
  }

  isDoctorSelectable(): boolean {
    return this.user.role === "DATA_OFFICER"
      && (this.item.foundMeasurement.status === "UPLOADED"
        || this.item.foundMeasurement.status === "PRE_ANALYSED"
        || (this.item.foundMeasurement.status === "READY_FOR_ANALYSIS"
          && this.item.foundMeasurement.doctor?.id !== undefined
          && this.item.foundMeasurement.doctor!.id! !== this.item.foundMeasurement.technician?.id
          && ReleaseToggleState.getInstance().isReleased("REASSIGN_DOCTOR_276"))
      );
  }

  shouldDisplayAssignedLabel(): boolean {
    return !!this.item.foundMeasurement.doctor && !this.isDoctorSelectable();
  }

  onDeleteMeasurementClick() {
    this.modalRefDeleteMeasurementConfirm = this.modalService.show(this.templateConfirmDeleteMeasurement, {
      class: "modal-sm",
      initialState: this.item.foundMeasurement
    });
  }

  private isMeasurementDeletable() {
    return this.item.foundMeasurement.status === "INITIATED"
      && !this.item.foundMeasurement.deleted;
  }

  deleteMeasurement() {
    this.item.isDeleteMeasurementInProgress = true;
    this.deleteMeasurementService.deleteInitiatedMeasurement(this.item.foundMeasurement.id)
      .pipe(finalize(() => (this.item.isDeleteMeasurementInProgress = false)))
      .subscribe((newMeasurement) => {
        this.item.foundMeasurement = newMeasurement;
        if (newMeasurement.deleted) {
          this.measurementChanged.emit();
        }
      }, () => {
        this.notifications.addNotification(NotificationType.ERROR, localizationKey("deletingMeasurementFailed"));
      });
    this.modalRefDeleteMeasurementConfirm!.hide();
  }

  shouldShowNurseDeleteButton() {
    return this.user.role === "NURSE" && this.isMeasurementDeletable();
  }

  generateDeleteMeasurementConfirmMessage() {
    return replaceAnchors(
      this.i18nService
        .getLocalizedString("confirmDeleteMeasurement"),
      {code: this.item.foundMeasurement.code});
  }

  emitMeasurementChanged() {
    this.measurementChanged.emit();
  }

  onMeasurementEdited(measurement: FoundMeasurement) {
    this.item.foundMeasurement = measurement;
  }

  shouldMarkCurrentDoctorInDoctorSelect() {
    return this.item.foundMeasurement.status !== "READY_FOR_ANALYSIS";
  }

  getHomeStatusLocalizationKey(): keyof LocalizationStrings {
    return getHomeStatusLocalizationKey(this.item.foundMeasurement.homeStatus!);
  }

  getStatusLocalizationKey(): keyof LocalizationStrings | undefined {
    return getStatusLocalizationKey(
      {
        status: this.item.foundMeasurement.status,
        role: this.user.role,
        isOrdered: this.item.foundMeasurement.hasOperatorHospital,
        isHome: this.item.foundMeasurement.isHome
      }
    );
  }
}

