import {Inject, Injectable, InjectionToken} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {catchError, map, tap} from "rxjs/operators";
import {AuthenticationService} from "../features/authentication/authentication.service";
import {ErrorResponseHandler} from "./error-response-handler";
import {environment} from "../../environments/environment";
import {VersionMismatch} from "../version-mismatch";

export const BASE_URL = new InjectionToken<string>("baseurl");

// only satisfies Angular DI, local baseUrl is directly set in API-tests without Angular DI
export function productionBaseUrlProvider() {
  return {provide: BASE_URL, useValue: environment.baseUrl};
}

@Injectable()
export class ApiClient {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly authenticationService: AuthenticationService,
    private readonly errorResponseHandler: ErrorResponseHandler,
    @Inject(BASE_URL)
    private readonly baseUrl: String = "",
    private readonly versionMismatch: VersionMismatch
  ) {
  }

  get<T>(url: string, params?: { [param: string]: string | string[] }): Observable<T> {
    return this.transformErrorResponse(this.httpClient.get<T>(
      this.getFullUrl(url),
      {
        headers: this.setCustomHeaders(),
        params: params,
        observe: "response",
      }
    ));
  }

  put<T>(url: string, body?: any): Observable<T> {
    return this.transformErrorResponse(this.httpClient.put<T>(this.getFullUrl(url), body, {
      headers: this.setCustomHeaders(),
      observe: "response"
    }));
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.transformErrorResponse(this.httpClient.post<T>(this.getFullUrl(url), body, {
      headers: this.setCustomHeaders(),
      observe: "response"
    }));
  }

  patch<T>(url: string, body: any): Observable<T> {
    return this.transformErrorResponse(this.httpClient.patch<T>(this.getFullUrl(url), body, {
      headers: this.setCustomHeaders(),
      observe: "response"
    }));
  }

  delete<T>(url: string): Observable<T> {
    return this.transformErrorResponse(this.httpClient.delete<T>(this.getFullUrl(url), {
      headers: this.setCustomHeaders(),
      observe: "response"
    }));
  }

  private setCustomHeaders(): HttpHeaders | undefined {

    let headers = new HttpHeaders();

    const user = this.authenticationService.getCurrentAuthenticatedUser();

    if (user && user.token) {
      headers = headers.set("Authorization", `Bearer ${user.token}`);
    }

    headers = headers.set("Frontend-Version", `${environment.version}`);

    return headers;
  }

  private getFullUrl(url: string): string {
    if (url.startsWith("/")) {
      return this.baseUrl + url;
    } else {
      return this.baseUrl + "/" + url;
    }
  }

  private transformErrorResponse<T>(response: Observable<HttpResponse<T>>): Observable<T> {
    // @ts-ignore
    return response.pipe(
      tap(responseData => {
        const backendVersion = responseData.headers.getAll("Backend-Version");
        this.versionMismatch.setBackendVersion(backendVersion);
      }),
      catchError(this.errorResponseHandler.getHandler()),
    )
      .pipe(
        map(httpResponse => httpResponse.body)
      );
  }
}

