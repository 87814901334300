import {FormValidator} from "../../../tools/form/form-validator";
import {UploadFormModel} from "../../upload/upload-form-model";
import {isExisting} from "../../validations";

export class HomeEFormValidator extends FormValidator<UploadFormModel> {

  constructor(uploadData: UploadFormModel) {
    super(uploadData, createHomeMeasurementFormValidationMap(uploadData));
  }
}

function createHomeMeasurementFormValidationMap(uploadData: UploadFormModel) {
  return new Map<keyof UploadFormModel, (value: any) => boolean>()
    .set("gender", () => isExisting(uploadData.gender))
    .set("age", () => isExisting(uploadData.age))
    .set("height", () => isExisting(uploadData.height))
    .set("weight", () => isExisting(uploadData.weight));
}
