import {Component, Input} from "@angular/core";
import {UploadFormConfig} from "../upload-form-config";

@Component({
  selector: "app-sleep-upload-form",
  templateUrl: "./sleep-upload-form.component.html",
})
export class SleepUploadFormComponent {
  private _formConfig?: UploadFormConfig;

  @Input() showPostMeasurementFields!: boolean;
  @Input() showPreMeasurementFields!: boolean;

  @Input()
  set formConfig(formConfig: UploadFormConfig) {
    this._formConfig = formConfig;
  }

  get formConfig(): UploadFormConfig {
    return this._formConfig!;
  }
}
