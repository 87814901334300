<button *ngIf="buttonState === 'INITIAL'"
        [class]="buttonClass"
        [disabled]="buttonDisabled"
        [type]="buttonType"
        [attr.data-testid]="testId"
        [title]="toolTip === undefined ? '' : toolTip | localize"
        (click)="onButtonClick()"
>
  {{ buttonLabel | localize }}<i class="fa fa-chevron-right mx-1"></i>
</button>

<button *ngIf="buttonState === 'CONFIRMATION'"
        [attr.data-testid]="'confirm-'+testId"
        [type]="buttonType"
        [class]="buttonClass"
        (click)="onButtonClick()"
        [title]="toolTip === undefined ? '' : toolTip | localize">
  {{ confirmationLabel | localize }}
</button>

<button *ngIf="buttonState === 'ACTION_IN_PROGRESS'"
        [type]="buttonType"
        [class]="buttonClass"
        [disabled]="true"
        [attr.data-testId]="testId + '-progress-indicator'">
  <span [class]="spinnerClass"></span>
  <ng-container *ngIf="actionInProgressLabel">
    {{ actionInProgressLabel | localize }}
  </ng-container>
</button>
