import {Component, Input} from "@angular/core";
import {UploadFormConfig} from "../upload-form-config";
import {MeasurementType} from "../../measurement-models";

@Component({
  selector: "app-form-selector",
  templateUrl: "./upload-form-selector.component.html",
})
export class UploadFormSelectorComponent {

  @Input() type!: MeasurementType;
  @Input() showPostMeasurementFields = true;
  @Input() showPreMeasurementFields = true;
  @Input() isHome = false;
  @Input() formConfig!: UploadFormConfig;

}
