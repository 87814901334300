<div
  *ngIf="eFormLoaded"
  data-testId="e-form"
  class="container">

  <div class="mx-3 my-5">
    <eform-header
      *ngIf="isHomeMeasurement !== undefined"
      [isHomeMeasurement]="isHomeMeasurement"
    ></eform-header>
  </div>

  <hr/>

  <e-form-patient-data
    [type]="type!"
    [formConfig]="formConfig!"
    [homeStatus]="homeStatus"
  >
  </e-form-patient-data>


  <eform-mailing-info
    *ngIf="isHomeMeasurement && !mailingInfoSet"
    [homeEFormValidator]="homeEFormValidator"
  ></eform-mailing-info>

  <finish-measuring
    [homeStatus]="homeStatus"
    [startTime]="uploadFormModel.measurementStartTime"
    [eFormId]="eFormId!"
    [measurementType]="type!"
  >
  </finish-measuring>

  <div class="mx-3 my-5">
    <app-eform-footer
      *ngIf="isHomeMeasurement !== undefined"
      [isHomeMeasurement]="isHomeMeasurement"
    ></app-eform-footer>
  </div>
</div>

<div *ngIf="!eFormLoaded" data-testId="e-form-loading-indicator">
  <loading-indicator></loading-indicator>
</div>
