import {emptyEcgSymptom, emptyPatientData, MAX_ECG_SYMPTOMS, PatientData} from "./upload-model";
import {NurseMeasurementType} from "../initiate-measurement/initiate-measurement-data";

export type UploadFormModel = CommonMeasurementData & PatientData;

export interface CommonMeasurementData {
  measurementCode: string;
  measurementType?: NurseMeasurementType;
  measurementFileName: string;
  measurementStartTime?: string;
  measurementSessionId: number;
  measurementNurseComments?: string;

  reportLanguage?: string;
}

export const EMPTY_COMMON_MEASUREMENT_DATA: CommonMeasurementData = {
  measurementCode: "",
  measurementType: undefined,
  measurementFileName: "",
  measurementSessionId: 0,
  measurementStartTime: undefined,
  measurementNurseComments: undefined,
  reportLanguage: undefined
};

export function emptyUploadFormModel(
  commonData: CommonMeasurementData = EMPTY_COMMON_MEASUREMENT_DATA,
  patientData: PatientData = emptyPatientData()
): UploadFormModel {
  return {
    ...commonData,
    ...patientData,
    symptoms: [...new Array(MAX_ECG_SYMPTOMS)].map((_) => emptyEcgSymptom())
  };
}
