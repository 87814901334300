<div *ngIf="!newDesignReleased" class="container text-center">
  <div *ngIf="languageFeatureReleased">
    <ng-container *ngFor="let language of availableLanguages; let index = index">
      <span *ngIf="index > 0"> | </span>
      <a
        [attr.data-testId]="'language-' + language.code"
        class="btn-link"
        (click)="setLanguage(language.code)"
        [ngClass]="getLanguageNgClass(language.code)"
      >{{ language.name }}</a>
    </ng-container>
  </div>
  <h1 data-testId="title" class="mb-5">{{ "eFormLandingPageTitle" | localize }}</h1>
  <div class="form-group form-group-lg col">
    <label for="code">{{ "eFormCodeInputLabel" | localize }}</label>
  </div>
  <input
    id='code'
    (ngModelChange)="onCodeChanged($event)"
    [ngModel]="code"
    data-testId="code-input"
    class="form-control form-control-lg"
    autocomplete="off"
    style="font-weight: bold; letter-spacing: 0.2em; font-size: 40px; text-align: center"
  />
  <div style="height: 60px" class="container d-flex">
    <span
      *ngIf="codeInvalid"
      class="row justify-content-center align-self-center text-danger"
      data-testId="code-error-label"
    >{{ "eFormCodeInputErrorLabel" | localize }}</span>
  </div>
  <div style="display: inline-block">
    <re-captcha (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled"></re-captcha>
  </div>
  <div>
    <button
      type="submit"
      data-testId="submit-button"
      (click)="onSubmitClick()"
      class="btn btn-primary btn-lg"
      [disabled]="codeInvalid || !captchaToken"
    >{{ "eFormSubmitButton" | localize }}
    </button>
  </div>
</div>


<div *ngIf="newDesignReleased"
     class="landing-page-text"
     [ngClass]="{
       'data-form-landing-page-background': !isHomeMeasurement,
       'home-holter-landing-page-background': isHomeMeasurement
     }"
>
  <div class="container pt-5">

    <div class="row mb-4">
      <div class="col-sm-6 mb-3 text-center text-sm-left pl-0">
        <h5 class="text-white">
          <img
            src="assets/landing-page/logo.svg"
            style="height: 32px"
            class="mr-2"
          />
          {{ getTitleFromDomain(domainName) }}</h5>
      </div>
      <div class="col-sm-6 text-center text-sm-right pb-4 pr-0">

        <h6 class="text-white mb-3">Powered by <a class="landing-page-link" href="https://remotest.eu/"
                                                  target="_blank"
                                                  rel="noreferrer noopener">RemoTest</a></h6>

        <div *ngIf="languageFeatureReleased">
          <ng-container *ngFor="let language of availableLanguages; let index = index">
            <span *ngIf="index > 0" class="text-white"> | </span>
            <strong
              class="text-white"
              *ngIf="selectedLanguage===language.code">
              {{ language.name }}
            </strong>
            <a
              *ngIf="selectedLanguage!==language.code"
              href="#"
              [attr.data-testId]="'language-' + language.code"
              class="landing-page-link "
              (click)="setLanguage(language.code)"
              [ngClass]="getLanguageNgClass(language.code)"
            >{{ language.name }}</a>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row bg-white p-5" style="border-radius: 25px">
      <div class="col-md-6">
        <h3
          data-testId="greeting"
          style="color: #0096A1; font-weight: 700; font-size: 32px; line-height: 1.5;"
        >
          {{ "eFormLandingPageGreeting" | localize }}
        </h3>
        <div>
          <p>
            <strong>{{ getTitleFromDomain(domainName) }} </strong>
            {{ description | localize }}
          </p>
          <p *ngIf="!isHomeMeasurement">{{ "eFormLandingPageThankYou" | localize }}</p>
          <p *ngIf="isHomeMeasurement">{{ "homeHolterLandingPageCustomerService" | localize }}: <a
            style="color: rgb(17, 139, 126); text-decoration: underline;"
            href="{{'mailto:' + getEmailAddressFromDomain(domainName) }}">{{ getEmailAddressFromDomain(domainName) }}</a>
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <label for="code" style="font-weight: 500">{{ "eFormCodeInputLabel" | localize }}</label>
        <span class="text-danger"> *</span>
        <input
          id='code'
          (ngModelChange)="onCodeChanged($event)"
          [ngModel]="code"
          data-testId="code-input"
          class="form-control landing-page-input"
          autocomplete="off"
          style="
          font-weight: bold;
          letter-spacing: 0.2em;
          font-size: 40px;
          border-width: 3px;
          border-color: rgba(226, 226, 226, 1);
          border-radius: 7px

"
        />
        <div style="height: 40px">
          <small
            *ngIf="codeInvalid"
            class="text-danger"
            data-testId="code-error-label"
          >{{ "eFormCodeInputErrorLabel" | localize }}
          </small>
        </div>
        <div style="display: inline-block">
          <re-captcha (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled"></re-captcha>
        </div>
        <button
          type="submit"
          data-testId="submit-button"
          (click)="onSubmitClick()"
          class="btn btn-success btn-lg w-100 mt-3"
          [disabled]="codeInvalid || !captchaToken"
        >{{ "eFormSubmitButton" | localize }}
        </button>
      </div>
    </div>
  </div>
</div>
