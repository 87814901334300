import {Pipe, PipeTransform} from "@angular/core";
import {DateTimeString, formatDateTime, FormatType} from "./date-time-utils";

@Pipe({
  name: "appLocalDateTime",
})
export class AppLocalDateTimePipe implements PipeTransform {
  transform(value: DateTimeString): string {
    return formatDateTime(value, FormatType.LOCAL);
  }
}
