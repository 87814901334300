import {Injectable} from "@angular/core";
import {Observable, ReplaySubject, Subject} from "rxjs";

const REMOVE_NOTIFICATION_AFTER_MS = 5000;

@Injectable()
export class Notifications {
  private readonly added = new Subject<Notification>();
  readonly added$: Observable<Notification> = this.added.asObservable();

  private readonly deleteAll = new ReplaySubject<void>(1);
  readonly deleteAll$: Observable<void> = this.deleteAll.asObservable();

  addNotification(type: NotificationType, message: string) {
    this.added.next({
      type,
      message,
      timeoutMillis: type === NotificationType.OK ? REMOVE_NOTIFICATION_AFTER_MS : undefined
    });
  }

  addOkNotificationWithoutTimeout(message: string) {
    this.added.next({
      type: NotificationType.OK,
      message,
      timeoutMillis: undefined
    });
  }

  clearAll() {
    this.deleteAll.next();
  }
}

export enum NotificationType {
  OK = "ok",
  ERROR = "error",
  WARNING = "warning",
}

export interface Notification {
  type: NotificationType;
  message: string;
  timeoutMillis?: number;
}
