export interface AuthenticatedUser {
  username: string;
  role: UserRole;
  token: string;
  otpRequired?: boolean;
  language: string;
  canAccessCombinedReports: boolean;
  isChainDoctor: boolean;
}

export const ALL_USER_ROLES = ["NURSE", "DATA_OFFICER", "DOCTOR", "ORDERING_NURSE", "HMO"] as const;
type UserRoleTuple = typeof ALL_USER_ROLES;
export type UserRole = UserRoleTuple[number]; // union type

export function isValidAuthenticatedUser(user: AuthenticatedUser) {
  return !!user && !!user.username && !!user.role && !!user.token;
}
