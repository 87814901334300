export function getTitleFromDomain(text: string) {
  const strippedLeadingWww = stripLeadingWww(text);
  return capitalizeFirstLetter(strippedLeadingWww);
}

function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function stripLeadingWww(text: string): string {
  if (text.length === 0) {
    return text;
  }

  if (text.toLowerCase() === "www") {
    return "";
  }

  return text.toLowerCase().startsWith("www.")
    ? text.substring(4)
    : text;
}
