import {localizationKey, LocalizationStrings} from "../../i18n/i18n-model";
import {FormConfigBase, FormFieldConfigParams} from "../../tools/form/form-config-base";
import {FormFieldConfig, FormFieldType, PropertyChangeListener} from "../../tools/form/form-field-config";
import {SelectionOptions} from "../../tools/form/form-field.component";
import {
  FallingAsleepDuration,
  Frequency,
  Overweight,
  Probability,
  SleepMeasurementNight,
  SleepQuality,
  Smoking,
} from "./upload-model";
import {UploadFormModel} from "./upload-form-model";
import {measurementTypeOptions} from "../measurement-type-select-options";
import {languageSelectionOptions} from "../../tools/languages";
import {NurseMeasurementType} from "../initiate-measurement/initiate-measurement-data";
import {environment} from "../../../environments/environment";

export class UploadFormConfig extends FormConfigBase<UploadFormModel> {

  private isEForm = environment.eforms;

  constructor(
    model: UploadFormModel,
    propertyChangeListener?: PropertyChangeListener<UploadFormModel>,
    validationProvider?: (property: keyof UploadFormModel) => boolean | undefined,
    private uploadingInitiatedMeasurement = false,
    private uploadingOrderedMeasurement = false,
    allowedTypeOptions: SelectionOptions<NurseMeasurementType> = measurementTypeOptions,
    editingMeasurement = false,
    private isHomeEForm = false
  ) {
    super(model, propertyChangeListener, validationProvider);
    this.id = this.createRequiredUploadFormField(
      "measurementCode",
      "text",
      "uploadFormLabelCode",
      "uploadFormInvalidLabelCode",
      undefined,
      this.uploadingInitiatedMeasurement
    );

    this.type = this.createSelectableField(
      "measurementType",
      "dropdown",
      "uploadFormTypeFieldLabel",
      allowedTypeOptions,
      undefined,
      true,
      this.uploadingInitiatedMeasurement
    );

    this.reasonForStudy = this.createField({
        property: "reasonForStudy",
        type: "textarea",
        label: "uploadFormLabelReasonForStudy",
        invalidLabel: "initiateMeasurementReasonForStudyInvalid",
        readOnly: this.uploadingOrderedMeasurement,
        filledByNurseOnly: true,
        required: true,
        autoexpand: true
      }
    );

    if (!editingMeasurement) {
      this.nurseComments = this.createField({
        property: "measurementNurseComments",
        type: "textarea",
        label: "uploadFormLabelNurseComments",
        filledByNurseOnly: true,
        invalidLabel: "textTooLongError",
        autoexpand: true
      });
    }

    this.gender = this.createSelectableField(
      "gender",
      "radio",
      "uploadFormLabelGender",
      this.genderOptions,
      "uploadFormInvalidLabelGender",
      this.isUploadFormOrHomeEform()
    );

    this.age = this.createRequiredUploadFormOrHomeEFormField(
      "age",
      "number",
      "uploadFormLabelAge",
      "uploadFormInvalidLabelAge",
      "uploadFormInfoLabelAge"
    );

    this.height = this.createRequiredUploadFormOrHomeEFormField(
      "height",
      "number",
      "uploadFormLabelHeight",
      "uploadFormInvalidLabelHeight",
      "uploadFormInfoLabelHeight"
    );

    this.weight = this.createRequiredUploadFormOrHomeEFormField(
      "weight",
      "number",
      "uploadFormLabelWeight",
      "uploadFormInvalidLabelWeight",
      "uploadFormInfoLabelWeight"
    );

  }

  readonly id;

  readonly type: FormFieldConfig<UploadFormModel>;

  readonly reasonForStudy: FormFieldConfig<UploadFormModel>;

  readonly frequencyHeaderLabel: keyof LocalizationStrings = "uploadFormHeaderFrequency";
  readonly probabilityHeaderLabel: keyof LocalizationStrings = "uploadFormHeaderProbability";
  readonly eventsDuringMeasurementLabel: keyof LocalizationStrings = "uploadFormHeaderEventsDuringMeasurementLabel";

  private readonly yesNoOptions = SelectionOptions.with<boolean>(
    {value: true, text: localizationKey("uploadFormOptionYes")},
    {value: false, text: localizationKey("uploadFormOptionNo")}
  );

  private readonly noYesOptions = SelectionOptions.with<boolean>(
    {value: false, text: localizationKey("uploadFormOptionNo")},
    {value: true, text: localizationKey("uploadFormOptionYes")}
  );

  readonly file = this.createRequiredUploadFormField(
    "measurementFileName",
    "wrapper",
    "uploadFormMeasurementFileLabel",
    "uploadFormInvalidLabelFile"
  );

  readonly measurementStartTime = this.createField({
    property: "measurementStartTime",
    type: "localdate",
    label: "uploadFormLabelMeasurementStartTime",
    invalidLabel: "uploadFormInvalidLabelRequired",
    required: true
  });

  private readonly genderOptions = SelectionOptions.with<string>(
    {value: "F", text: localizationKey("uploadFormLabelGenderF")},
    {value: "M", text: localizationKey("uploadFormLabelGenderM")}
  );

  readonly gender: FormFieldConfig<UploadFormModel>;

  readonly age: FormFieldConfig<UploadFormModel>;

  readonly height: FormFieldConfig<UploadFormModel>;

  readonly weight: FormFieldConfig<UploadFormModel>;

  readonly waistline = this.createUploadFormField({
    property: "waistline",
    type: "number",
    label: "uploadFormLabelWaistline",
    invalidLabel: "uploadFormInvalidLabelWaistline",
    infoLabel: "uploadFormInfoLabelWaistline"
  });

  readonly cholesterol = this.createUploadFormField({
    property: "cholesterol",
    type: "number",
    label: "uploadFormLabelCholesterol",
    invalidLabel: "uploadFormInvalidLabelCholesterol",
    infoLabel: "uploadFormInfoLabelCholesterol"
  });

  private readonly overweightOptions = SelectionOptions.with<Overweight>(
    {value: "NO", text: localizationKey("uploadFormOptionNo")},
    {value: "LITTLE", text: localizationKey("uploadFormOptionLittle")},
    {value: "LOT", text: localizationKey("uploadFormOptionLot")}
  );

  readonly overweight = this.createSelectableField("overweight", "radio", "uploadFormLabelOverweight", this.overweightOptions);

  readonly profession = this.createUploadFormField({
    property: "profession",
    type: "text",
    label: "uploadFormLabelProfession",
    invalidLabel: "uploadFormInvalidLabelProfession"
  });

  readonly hypertension = this.createSelectableField("hypertension", "radio", "uploadFormLabelHypertension", this.yesNoOptions);
  readonly diabetes = this.createSelectableField("diabetes", "radio", "uploadFormLabelDiabetes", this.yesNoOptions);
  readonly coronaryArteryDisease = this.createSelectableField(
    "coronaryArteryDisease",
    "radio",
    "uploadFormLabelCoronaryArteryDisease",
    this.yesNoOptions
  );
  readonly coronaryInsufficiency = this.createSelectableField(
    "coronaryInsufficiency",
    "radio",
    "uploadFormLabelCoronaryInsufficiency",
    this.yesNoOptions
  );
  readonly cerebrovascularDisorders = this.createSelectableField(
    "cerebrovascularDisorders",
    "radio",
    "uploadFormLabelCerebrovascularDisorders",
    this.yesNoOptions
  );
  readonly kidneyDisease = this.createSelectableField("kidneyDisease", "radio", "uploadFormLabelKidneyDisease", this.yesNoOptions);
  readonly overbite = this.createSelectableField("overbite", "radio", "uploadFormLabelOverbite", this.yesNoOptions);
  readonly cloggedNasalPassage = this.createSelectableField(
    "cloggedNasalPassage",
    "radio",
    "uploadFormLabelCloggedNasalPassage",
    this.yesNoOptions
  );
  readonly allergicRhinitis = this.createSelectableField("allergicRhinitis", "radio", "uploadFormLabelAllergicRhinitis", this.yesNoOptions);
  readonly smallChin = this.createSelectableField("smallChin", "radio", "uploadFormLabelSmallChin", this.yesNoOptions);

  readonly parentsHeartAttackUnder60 = this.createSelectableField(
    "parentsHeartAttackUnder60",
    "radio",
    "uploadFormLabelParentsHeartAttack60",
    this.yesNoOptions
  );
  readonly parentsStrokeUnder75 = this.createSelectableField(
    "parentsStrokeUnder75",
    "radio",
    "uploadFormLabelParentsStroke75",
    this.yesNoOptions
  );

  private readonly sleepMeasurementNightOptions = SelectionOptions.with<SleepMeasurementNight>(
    {value: "WELL", text: localizationKey("uploadFormOptionWell")},
    {value: "POORLY", text: localizationKey("uploadFormOptionPoorly")},
    {value: "NOT_AT_ALL", text: localizationKey("uploadFormOptionNotAtAll")}
  );

  readonly sleepMeasurementNight = this.createSelectableField(
    "sleepMeasurementNight",
    "radio",
    "uploadFormLabelSleepMeasurementNight",
    this.sleepMeasurementNightOptions
  );

  readonly goToBedTime = this.createRequiredUploadFormField(
    "goToBedTime",
    "localdate",
    "uploadFormLabelGoToBedTime",
    "uploadFormInvalidLabelGoToBedWakeUp"
  );

  readonly wakeUpTime = this.createRequiredUploadFormField(
    "wakeUpTime",
    "localdate",
    "uploadFormLabelWakeUpTime",
    "uploadFormInvalidLabelGoToBedWakeUp"
  );

  private readonly smokingOptions = SelectionOptions.with<Smoking>(
    {value: "NO", text: localizationKey("uploadFormOptionNo")},
    {value: "SOMETIMES", text: localizationKey("uploadFormOptionSometimes")},
    {value: "DAILY", text: localizationKey("uploadFormOptionDaily")}
  );

  readonly smoking = this.createSelectableField("smoking", "radio", "uploadFormLabelSmoking", this.smokingOptions);

  readonly useOfSleepingPills = this.createSelectableField(
    "useOfSleepingPills",
    "radio",
    "uploadFormLabelUseOfSleepingPills",
    this.noYesOptions
  );

  private readonly alcoholConsumptionOptions = SelectionOptions.with<string>(
    {value: "0-5", text: "0 - 5"},
    {value: "5-10", text: "5 - 10"},
    {value: "10-20", text: "10 - 20"},
    {value: "20", text: localizationKey("alcoholConsumptionOptionOver20")}
  );

  readonly alcoholConsumption = this.createSelectableField(
    "alcoholConsumption",
    "radio",
    "uploadFormLabelAlcoholConsumption",
    this.alcoholConsumptionOptions
  );

  readonly frequencyHeaderColumnLabels: (keyof LocalizationStrings)[] = [
    "uploadFormOptionFrequencyDaily",
    "uploadFormOptionFrequencyWeekly",
    "uploadFormOptionFrequencyMonthly",
    "uploadFormOptionFrequencyRarely",
  ];

  private readonly frequencyOptions = SelectionOptions.inColumnsWith<Frequency>(
    {value: "DAILY", text: this.frequencyHeaderColumnLabels[0]},
    {value: "WEEKLY", text: this.frequencyHeaderColumnLabels[1]},
    {value: "MONTHLY", text: this.frequencyHeaderColumnLabels[2]},
    {value: "RARELY", text: this.frequencyHeaderColumnLabels[3]}
  );

  readonly morningHeadaches = this.createSelectableField(
    "morningHeadaches",
    "radio",
    "uploadFormLabelMorningHeadaches",
    this.frequencyOptions
  );
  readonly breathingLoss = this.createSelectableField("breathingLoss", "radio", "uploadFormLabelBreathingLoss", this.frequencyOptions);
  readonly fluttering = this.createSelectableField("fluttering", "radio", "uploadFormLabelFluttering", this.frequencyOptions);
  readonly snoring = this.createSelectableField("snoring", "radio", "uploadFormLabelSnoring", this.frequencyOptions);
  readonly restlessSleep = this.createSelectableField("restlessSleep", "radio", "uploadFormLabelRestlessSleep", this.frequencyOptions);
  readonly daytimeSleepiness = this.createSelectableField(
    "daytimeSleepiness",
    "radio",
    "uploadFormLabelDaytimeSleepiness",
    this.frequencyOptions
  );
  readonly dryMouthInMorning = this.createSelectableField(
    "dryMouthInMorning",
    "radio",
    "uploadFormLabelDryMouthInMorning",
    this.frequencyOptions
  );
  readonly needToUrinate = this.createSelectableField("needToUrinate", "radio", "uploadFormLabelNeedToUrinate", this.frequencyOptions);

  readonly probabilityHeaderColumnLabels: (keyof LocalizationStrings)[] = [
    "uploadFormOptionProbabilityZero",
    "uploadFormOptionProbabilitySmall",
    "uploadFormOptionProbabilityModerate",
    "uploadFormOptionProbabilityLarge",
  ];
  private readonly probabilityOptions = SelectionOptions.inColumnsWith<Probability>(
    {value: "ZERO", text: this.probabilityHeaderColumnLabels[0]},
    {value: "SMALL", text: this.probabilityHeaderColumnLabels[1]},
    {value: "MODERATE", text: this.probabilityHeaderColumnLabels[2]},
    {value: "LARGE", text: this.probabilityHeaderColumnLabels[3]}
  );

  readonly sittingReading = this.createSelectableField("sittingReading", "radio", "uploadFormLabelSittingReading", this.probabilityOptions);
  readonly watchingTelevision = this.createSelectableField(
    "watchingTelevision",
    "radio",
    "uploadFormLabelWatchingTelevision",
    this.probabilityOptions
  );
  readonly sittingPassive = this.createSelectableField("sittingPassive", "radio", "uploadFormLabelSittingPassive", this.probabilityOptions);
  readonly travelInCarForAnHour = this.createSelectableField(
    "travelInCarForAnHour",
    "radio",
    "uploadFormLabelTravelInCarForAnHour",
    this.probabilityOptions
  );
  readonly lyingDownInAfternoon = this.createSelectableField(
    "lyingDownInAfternoon",
    "radio",
    "uploadFormLabelLyingDownInAfternoon",
    this.probabilityOptions
  );
  readonly sittingTalkingToSomeone = this.createSelectableField(
    "sittingTalkingToSomeone",
    "radio",
    "uploadFormLabelSittingTalkingToSomeone",
    this.probabilityOptions
  );
  readonly sitInPeaceAfterNonAlcoholicLunch = this.createSelectableField(
    "sitInPeaceAfterNonAlcoholicLunch",
    "radio",
    "uploadFormLabelSitInPeaceAfterNonAlcoholicLunch",
    this.probabilityOptions
  );
  readonly sitInCarAfterItHasStoppedForFewMinutes = this.createSelectableField(
    "sitInCarAfterItHasStoppedForFewMinutes",
    "radio",
    "uploadFormLabelSitInCarAfterItHasStoppedForFewMinutes",
    this.probabilityOptions
  );

  readonly diagnosedHeartDisease = this.createUploadFormField({
    property: "diagnosedHeartDisease",
    type: "textarea",
    label: "uploadFormLabelDiagnosedHeartDisease",
    invalidLabel: "textTooLongError",
    autoexpand: true
  });

  readonly currentMedication = this.createUploadFormField({
    property: "currentMedication",
    type: "textarea",
    label: "uploadFormLabelCurrentMedication",
    invalidLabel: "textTooLongError",
    autoexpand: true
  });

  private readonly sleepQualityOptions = SelectionOptions.with<SleepQuality>(
    {value: "BETTER", text: localizationKey("uploadFormOptionSleepQualityBetter")},
    {value: "SAME", text: localizationKey("uploadFormOptionSleepQualitySame")},
    {value: "WORSE", text: localizationKey("uploadFormOptionSleepQualityWorse")}
  );

  readonly sleepQuality = this.createSelectableField(
    "sleepQuality",
    "radio",
    "uploadFormLabelSleepQuality",
    this.sleepQualityOptions
  );

  private readonly fallingAsleepDurationOptions = SelectionOptions.with<FallingAsleepDuration>(
    {value: "SHORTER", text: localizationKey("uploadFormOptionFallingAsleepDurationShorter")},
    {value: "SAME", text: localizationKey("uploadFormOptionFallingAsleepDurationSame")},
    {value: "LONGER", text: localizationKey("uploadFormOptionFallingAsleepDurationLonger")}
  );
  readonly fallingAsleepDuration = this.createSelectableField(
    "fallingAsleepDuration",
    "radio",
    "uploadFormLabelFallAsleepDuration",
    this.fallingAsleepDurationOptions
  );

  readonly sleepHours = this.createUploadFormField({
    property: "sleepHours",
    type: "number",
    label: "uploadFormLabelSleepHours",
    invalidLabel: undefined,
    infoLabel: "uploadFormInfoLabelHours"
  });
  readonly minutesAwakeAfterMeasurementStart = this.createUploadFormField({
    property: "minutesAwakeAfterMeasurementStart",
    type: "number",
    label: "uploadFormLabelMinutesAwakeAfterMeasurementStart",
    invalidLabel: undefined,
    infoLabel: "uploadFormInfoLabelMinutes"
  });
  readonly awakeningsDuringMeasurement = this.createUploadFormField({
    property: "awakeningsDuringMeasurement",
    type: "number",
    label: "uploadFormLabelAwakeningsDuringMeasurement",
    invalidLabel: undefined,
    infoLabel: "uploadFormInfoLabelTimes"
  });

  readonly sleepDiary = this.createUploadFormField({
    property: "sleepDiary",
    type: "textarea",
    label: "uploadFormLabelSleepDiary",
    invalidLabel: "textTooLongError",
    autoexpand: true
  });
  readonly patientFeedback = this.createUploadFormField({
    property: "patientFeedback",
    type: "textarea",
    label: "uploadFormLabelPatientFeedback",
    invalidLabel: "textTooLongError",
    autoexpand: true
  });

  nurseComments?: FormFieldConfig<UploadFormModel>;

  readonly diary = this.createUploadFormField({
    property: "diary",
    type: "textarea",
    label: "uploadFormLabelDiary",
    invalidLabel: "textTooLongError",
    autoexpand: true
  });

  readonly abnormalHeartbeats = this.createSelectableField(
    "abnormalHeartbeats",
    "radio",
    "uploadFormLabelAbnormalHeartbeats",
    this.frequencyOptions
  );
  readonly slowPulse = this.createSelectableField("slowPulse", "radio", "uploadFormLabelSlowPulse", this.frequencyOptions);
  readonly fastPulse = this.createSelectableField("fastPulse", "radio", "uploadFormLabelFastPulse", this.frequencyOptions);
  readonly palpitation = this.createSelectableField("palpitation", "radio", "uploadFormLabelPalpitation", this.frequencyOptions);
  readonly irregularPulse = this.createSelectableField("irregularPulse", "radio", "uploadFormLabelIrregularPulse", this.frequencyOptions);
  readonly dizziness = this.createSelectableField("dizziness", "radio", "uploadFormLabelDizziness", this.frequencyOptions);
  readonly lossOfConsciousness = this.createSelectableField(
    "lossOfConsciousness",
    "radio",
    "uploadFormLabelLossOfConsciousness",
    this.frequencyOptions
  );
  readonly blackouts = this.createSelectableField("blackouts", "radio", "uploadFormLabelBlackouts", this.frequencyOptions);
  readonly chestPain = this.createSelectableField("chestPain", "radio", "uploadFormLabelChestPain", this.frequencyOptions);
  readonly shortnessOfBreath = this.createSelectableField(
    "shortnessOfBreath",
    "radio",
    "uploadFormLabelShortnessOfBreath",
    this.frequencyOptions
  );

  readonly ecgSymptoms = this.createUploadFormField({
    property: "symptoms",
    type: "wrapper",
    label: "uploadFormLabelSymptoms",
    invalidLabel: "uploadFormInvalidLabelEcgSymptoms"
  });

  readonly reportLanguage = this.createSelectableField(
    "reportLanguage",
    "dropdown",
    "uploadFormLabelReportLanguage",
    {
      items: languageSelectionOptions.map(languageOption => ({
        value: languageOption.value,
        text: languageOption.text,
        disabled: false
      })),
      isColumnLayout: false
    }
  );

  private createRequiredUploadFormOrHomeEFormField(
    property: keyof UploadFormModel,
    type: FormFieldType,
    label: keyof LocalizationStrings,
    invalidLabel?: keyof LocalizationStrings,
    infoLabel?: keyof LocalizationStrings,
    readOnly = false
  ): FormFieldConfig<UploadFormModel> {
    return this.createField({
      property,
      type,
      label,
      invalidLabel,
      infoLabel,
      required: this.isUploadFormOrHomeEform(),
      readOnly
    });
  }

  private createRequiredUploadFormField(
    property: keyof UploadFormModel,
    type: FormFieldType,
    label: keyof LocalizationStrings,
    invalidLabel?: keyof LocalizationStrings,
    infoLabel?: keyof LocalizationStrings,
    readOnly = false
  ): FormFieldConfig<UploadFormModel> {
    return this.createField({
      property,
      type,
      label,
      invalidLabel,
      infoLabel,
      required: !this.isEForm,
      readOnly
    });
  }

  private createUploadFormField(params: FormFieldConfigParams<UploadFormModel>): FormFieldConfig<UploadFormModel> {
    return this.createField({
      ...params,
      required: false
    });
  }

  private createSelectableField(
    property: keyof UploadFormModel,
    type: FormFieldType,
    label: keyof LocalizationStrings,
    selectionOptions: SelectionOptions<any>,
    invalidLabel?: keyof LocalizationStrings,
    required?: boolean,
    readOnly = false
  ): FormFieldConfig<UploadFormModel> {
    return this.createField({
      property,
      type,
      label,
      invalidLabel,
      required,
      selectionOptions,
      readOnly
    });
  }

  private isUploadFormOrHomeEform() {
    return !this.isEForm || this.isHomeEForm;
  }
}
