import {HomeMeasurementStatus} from "../found-measurement";
import {LocalizationStrings} from "../../../i18n/i18n-model";

export function getHomeStatusLocalizationKey(homeStatus: HomeMeasurementStatus) {
  const keys = new Map<HomeMeasurementStatus, keyof LocalizationStrings>()
    .set("WAITING_FOR_MAILING_INFO", "homeMeasurementStatusLabelWaitingForMailingInfo")
    .set("MAILING_TO_PATIENT", "homeMeasurementStatusLabelMailingToPatient")
    .set("MAILED_TO_PATIENT", "homeMeasurementStatusLabelMailedToPatient")
    .set("RETURNED_FROM_PATIENT", "homeMeasurementStatusLabelReturnedFromPatient");

  return keys.get(homeStatus)!;
}
