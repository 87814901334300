<div data-testId="mail-device-page">
  <form class="card-body">
    <p>
      <button
        data-testId="print-mailing-info-button"
        class="btn btn-secondary"
        (click)="printMailingInfo()"
      >
        {{ "mailingConfirmationPrintMailingInfoButtonLabel" | localize }}
      </button>
    </p>

    <form-field [config]="formConfig.deviceSerialNumber"></form-field>

    <p>
      <action-button
        [actionInProgress]="confirmingInProgress"
        buttonLabel="mailingConfirmationButtonLabel"
        actionInProgressLabel="mailingConfirmationButtonInProgressLabel"
        (buttonClick)="confirmDeviceMailing()"
        [testId]="'mailing-confirmation-button'"
        [buttonDisabled]="!mailingInfoPrinted"
      >
      </action-button>
    </p>
  </form>

</div>
