import {Component, EventEmitter, Input, Output} from "@angular/core";
import {LocalizationStrings} from "../../i18n/i18n-model";

export type ActionButtonState = "INITIAL" | "CONFIRMATION" | "ACTION_IN_PROGRESS";

@Component({
  selector: "app-pre-confirm-action-button",
  templateUrl: "./pre-confirm-action-button.component.html",
})
export class PreConfirmActionButtonComponent {

  @Input() buttonType: "submit" | "button" = "submit";

  @Input() buttonClass = "btn btn-primary";

  @Input() buttonLabel: keyof LocalizationStrings | undefined;

  @Input() iconClass: string | undefined;

  @Input() actionInProgressLabel: keyof LocalizationStrings | undefined;

  @Input() spinnerClass = "spinner-border spinner-border-sm";

  @Input() buttonDisabled = false;

  @Input() testId: String | undefined;

  @Input() toolTip: keyof LocalizationStrings | undefined;

  @Output()
  private buttonClick: EventEmitter<void> = new EventEmitter<void>();

  @Input() confirmationLabel: keyof LocalizationStrings | undefined = undefined;
  @Input() buttonState: ActionButtonState = "INITIAL";

  onButtonClick() {
    this.buttonClick.emit();
  }

}
