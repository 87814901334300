import {Injectable} from "@angular/core";
import {PrintFormModel} from "./print-form.component";
import {PDFDocument, PDFForm} from "pdf-lib";
import {map, mergeMap} from "rxjs/operators";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {ALL_MEASUREMENT_TYPES} from "../measurement-models";

@Injectable()
export class PdfFillerService {

  fillPdf(
    pdf: string,
    data: PrintFormModel,
    fileName: string | undefined
  ) {
    return fromPromise(PDFDocument.load(pdf))
      .pipe(mergeMap(
        pdfDoc => {
          const form = pdfDoc.getForm();
          if (ALL_MEASUREMENT_TYPES.some(type => type === data.type)) {
            form.getTextField("patient_name").setText(data.name);
            form.getTextField("patient_ssn").setText(data.ssn);
          }
          this.fillIfFieldExists(form, "patient_id", data.code);
          this.fillIfFieldExists(form, "unique_id", data.uniqueId);
          this.fillIfFieldExists(form, "e-form_code", data.eFormCode!);

          return fromPromise(pdfDoc.save());
        }))
      .pipe(map(pdfData => {
        const file = new Blob([pdfData], {type: "application/pdf"});
        const fileURL = URL.createObjectURL(file);

        if (fileName === undefined) {
          window.open(fileURL);
        } else {
          Object
            .assign(
              document.createElement("a"),
              {
                href: fileURL,
                download: fileName
              }
            )
            .click();
        }
      })
      );
  }

  private fillIfFieldExists(form: PDFForm, eFormCode: string, text: string) {
    if (form.getFields().some(field => field.getName() === eFormCode)) {
      form.getTextField(eFormCode).setText(text);
    }
  }
}
