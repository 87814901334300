<div class="container-fluid container--available-viewport-height">
  <div class="row justify-content-md-center">
    <loading-indicator *ngIf="measurementLoadingInProgress$ | async"></loading-indicator>
  </div>

  <div class="row h-100">
    <div class="h-100 overflow-auto col-md-6 col-md">
      <div id="leftPanel" *ngIf="ecgAnalysis">
        <div id="studyId" class="row pb-4">
          <div class="w-100">
            <span class="strong pl-4">{{ labelStudyId | localize }}</span>
            <span class="pl-2">{{ ecgAnalysis.code }}</span>
          </div>
          <div class="w-100">
            <span class="strong pl-4">{{ localizationKey("labelHospitalName") | localize }}</span>
            <span class="pl-2">{{ ecgAnalysis.hospitalName }}</span>
          </div>
          <div class="w-100">
            <span class="strong pl-4">{{ labelUniqueId | localize }}</span>
            <span class="pl-2">{{ ecgAnalysis.uniqueId }}</span>
          </div>
          <div class="w-100" *ngIf="ecgAnalysis.isExternallyAnalyzed">
            <span class="strong pl-4">{{ labelExternalAnalysisUrl | localize }}</span>
            <a data-testId="external-analysis-url" class="pl-2" *ngIf="ecgAnalysis.externalAnalysisUrl"
               [href]="ecgAnalysis.externalAnalysisUrl"
               target="_blank">{{ ecgAnalysis.externalAnalysisUrl }}</a>
          </div>
        </div>

        <div id="analysisForm">
          <form class="container form-striped">
            <app-patient-status-comments-generator
              *ngIf="isExternallyAnalyzed()"
              [analysisGenerationConfig]="ecgAnalysis.analysisGenerationConfig"
              (onGenerate)="setPatientStatusComments($event)"
              [config]="ecgFindingsFormConfig.patientStatusComments"
              [isInitiallyManualMode]="!!ecgAnalysis.doctorFindings.patientStatusComments"
            >
              <form-field [config]="ecgFindingsFormConfig.patientStatusComments"></form-field>
            </app-patient-status-comments-generator>
            <form-field
              *ngIf="!isExternallyAnalyzed()"
              [config]="ecgFindingsFormConfig.patientStatusComments"></form-field>

            <form-field
              *ngFor="let analysis of ecgSymptomAnalyses"
              [config]="analysis.ecgSymptomFindingFormConfig.finding(analysis.ecgSymptomDescription)"
            ></form-field>


            <app-findings-generator
              *ngIf="isExternallyAnalyzed()"
              [config]="ecgFindingsFormConfig.findings"
              [analysisGenerationConfig]="ecgAnalysis.analysisGenerationConfig"
              (onGenerate)="setFindings($event)"
              [measurementId]="ecgAnalysis.id"
              [isInitiallyManualMode]="!!ecgAnalysis.doctorFindings.findings"
            >
              <form-field [config]="ecgFindingsFormConfig.findings"></form-field>
            </app-findings-generator>
            <form-field
              *ngIf="!isExternallyAnalyzed()"
              [config]="ecgFindingsFormConfig.findings"></form-field>

            <form-field [config]="ecgFindingsFormConfig.conclusion"></form-field>

            <ng-container *ngIf="ecgAnalysis.type === 'HOLTER_ECG'">
              <app-diagnosis-generator
                *ngIf="isExternallyAnalyzed()"
                [config]="ecgFindingsFormConfig.diagnosis"
                [analysisGenerationConfig]="ecgAnalysis.analysisGenerationConfig"
                (onGenerate)="setDiagnosis($event)"
                [isInitiallyManualMode]="!!ecgAnalysis.doctorFindings.diagnosis"
              >
                <form-field [config]="ecgFindingsFormConfig.diagnosis"></form-field>
              </app-diagnosis-generator>
              <form-field
                *ngIf="!isExternallyAnalyzed()"
                [config]="ecgFindingsFormConfig.diagnosis"></form-field>
            </ng-container>

          </form>

          <div id="samplesAndPreview" *ngIf="!isPreviewMode">
            <div class="row" *ngIf="!ecgAnalysis.isExternallyAnalyzed">
              <div class="col-md-10 offset-md-2">
                <label class="mr-4">{{ labelSamplesDocument | localize }}:</label>
                <input
                  *ngIf="!foundEcgSamplesDocument"
                  type="file"
                  accept=".pdf"
                  (change)="onEcgSamplesFileSelected(getFirstSelectedFile($event))"
                />
                <div *ngIf="foundEcgSamplesDocument" class="pt-1 d-inline-block">
                  <span class="mr-2">{{ foundEcgSamplesDocument.fileName }}</span>
                  <button class="btn btn-sm btn-secondary" (click)="clearExistingEcgSamplesFile()">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
                <div *ngIf="!isEcgSamplesFileValid()" class="form-field-invalid-feedback">
                  {{ "analysisFormInvalidEcgFile" | localize }}
                </div>
              </div>
            </div>

            <div class="pt-2">
              <button
                *ngIf="!feature285Released"
                type="button"
                class="btn btn-link mt-2"
                data-testId="old-preview-button"
                (click)="previewEcgReport()"
              >
                <i class="fa fa-file mr-1"></i>{{ labelPreviewEcgReport | localize }}
              </button>
              <action-button
                *ngIf="feature285Released"
                type="button"
                class="btn btn-link mt-2"
                (click)="previewEcgReport()"
                [testId]="'preview-button'"
                [buttonLabel]="labelPreviewEcgReport"
                [actionInProgress]="previewInProgress"
              >
              </action-button>
            </div>
          </div>
        </div>

        <div id="saveAndFinish" *ngIf="!isPreviewMode">
          <div class="pt-4">
            <button *ngIf="!isFinishMode" type="button" class="btn btn-primary mr-4" (click)="switchToFinishMode()">
              {{ labelButtonFinish | localize }}<i class="fa fa-chevron-right mx-1"></i>
            </button>

            <action-button
              *ngIf="!isFinishMode"
              class="mr-4"
              buttonClass="btn btn-outline-primary"
              [buttonLabel]="labelSave"
              [actionInProgressLabel]="labelInProgress"
              (buttonClick)="onSaveButtonClick()"
              [actionInProgress]="saveInProgress$ | async"
            >
            </action-button>

            <action-button
              *ngIf="isFinishMode"
              class="mr-4"
              buttonClass="btn btn-primary"
              [buttonLabel]="labelButtonFinishConfirm"
              [actionInProgressLabel]="labelButtonFinishInProgress"
              (buttonClick)="onFinishAnalysisClick()"
              [actionInProgress]="finishAnalysisInProgress$ | async"
            >
            </action-button>
            <button *ngIf="isFinishMode" type="button" class="btn btn-secondary" (click)="switchToSaveMode()">
              {{ labelButtonFinishCancel | localize }}
            </button>
            <span *ngIf="!!errorMessage" class="alert alert-danger ml-4">{{ errorMessage | localize }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="h-100 col-md-6 col-md">
      <div class="h-95 overflow-auto">
        <div
          id="patientDataPanel"
          *ngIf="patientDataFields && ecgAnalysis"
          class="col-12 small form-grid form-grid-striped"
        >
          <div class="row">
            <app-form-field-display class="col-6" [formFieldConfig]="patientDataFields.gender"></app-form-field-display>
            <app-form-field-display class="col-6" [formFieldConfig]="patientDataFields.age"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6" [formFieldConfig]="patientDataFields.height"></app-form-field-display>
            <app-form-field-display class="col-6" [formFieldConfig]="patientDataFields.weight"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.smoking"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.abnormalHeartbeats"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.slowPulse"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.fastPulse"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.palpitation"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.irregularPulse"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.dizziness"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.lossOfConsciousness"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.blackouts"></app-form-field-display>
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.chestPain"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display class="col-6"
                                    [formFieldConfig]="patientDataFields.shortnessOfBreath"></app-form-field-display>
            <app-form-field-display [label]="labelStart" [value]="ecgAnalysis.startTime | appLocalDateTime"
                                    class="col-6"></app-form-field-display>
          </div>

          <div class="row">
            <app-form-field-display
              class="col-12"
              [formFieldConfig]="patientDataFields.diagnosedHeartDisease"
              [displayHorizontal]="false"
            ></app-form-field-display>
          </div>

          <div class="row">
            <app-form-field-display
              class="col-12"
              [formFieldConfig]="patientDataFields.currentMedication"
              [displayHorizontal]="false"
            ></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display
              [formFieldConfig]="patientDataFields.reasonForStudy"
              [displayHorizontal]="false"
              class="col-12"
            ></app-form-field-display>
          </div>
          <div class="row" *ngIf="isEcgHolterOrAtrialFibrillation()">
            <app-form-field-display [formFieldConfig]="patientDataFields.diary" [displayHorizontal]="false"
                                    class="col-12"></app-form-field-display>
          </div>
          <div class="row">
            <app-form-field-display
              [formFieldConfig]="patientDataFields.patientFeedback"
              [displayHorizontal]="false"
              class="col-12"
            ></app-form-field-display>
          </div>
        </div>
      </div>

      <div class="text-right mt-5">
        <button
          type="button"
          [ngClass]="'btn btn-sm mr-4 mb-1 ' + getButtonType()"
          data-testid="show-comments-button"
          (click)="onShowCommentsClick()">{{ getCommentButtonLabel('measurementsGridButtonLabelShowComments' | localize, ecgAnalysis!.commentsCount!) }}
        </button>
      </div>

    </div>
  </div>
</div>


<ng-template #templateComments>
  <div class="modal-body text-center">
    <app-comments
      [measurementId]="ecgAnalysis!.id"
      [code]="ecgAnalysis!.code"
      [hospitalName]="ecgAnalysis!.hospitalName"
      (commentsCountChanged)="onCommentsCountChanged($event)"
    >

    </app-comments>
    <button type="button" class="btn btn-primary" (click)="modalRefComments?.hide()">
      {{ "modalResponseClose" | localize }}
    </button>
  </div>
</ng-template>
