import {Component, Input, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {MailingInfo} from "../../../e-forms/form/mailing-info/e-form-mailing-info.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {MailDeviceService} from "../../../mail-device/mail-device.service";

@Component({
  selector: "app-mailing-info",
  templateUrl: "./mailing-info.component.html"
})
export class MailingInfoComponent {
  @Input() measurementId!: number;

  mailingInfo?: MailingInfo;

  modalRef: BsModalRef | undefined;
  @ViewChild("templateMailingInfo") templateMailingInfo!: TemplateRef<any>;

  gettingMailingInfoFailed = false;

  constructor(private readonly modalService: BsModalService,
              private readonly mailDeviceService: MailDeviceService
  ) {
  }

  showModal() {
    this.modalRef = this.modalService.show(this.templateMailingInfo, {
      class: "modal-lg"
    });

    this.mailDeviceService.getPatientMailingInfo(this.measurementId)
    .subscribe({
      next: (mailingInfo)=> {
        this.mailingInfo = mailingInfo;
      },
      error: ()=> {
        this.gettingMailingInfoFailed = true;
      }
    });
  }

  closeModal() {
    this.modalRef?.hide();
  }
}
