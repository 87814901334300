<ul class="navbar-nav" *ngIf="!!currentUser">
  <li class="nav-item active">
    <a class="nav-link" routerLink="/{{NavigationRoute.LIST}}"
       data-testid="nav-list">{{ "navigationList" | localize }}</a>
  </li>
  <li class="nav-item" *ngIf="isNurse()">
    <a class="nav-link" routerLink="/{{NavigationRoute.INITIATE_MEASUREMENT}}"
       data-testid="nav-initiate-measurement">{{ "navigationInitiateMeasurement" | localize }}</a>
  </li>
  <ng-container *ngIf="feature397Released()">
    <li
      class="nav-item"
      *ngIf="isNurse()"
    >
      <a
        class="nav-link"
        routerLink="/{{NavigationRoute.ORDER_HOME_MEASUREMENT}}"
        data-testid="nav-order-home-measurement">
        {{ "navigationOrderHomeMeasurement" | localize }}
      </a>
    </li>
  </ng-container>
  <li class="ml-5" *ngIf="isNurse()">
    <span class="nav-link">{{ localizationKey("navigationLabelOldServiceModel") | localize }}:</span>
  </li>
  <li class="nav-item" *ngIf="isNurse()">
    <a class="nav-link" routerLink="/{{NavigationRoute.UPLOAD}}"
       data-testid="nav-upload-measurement">{{ "navigationUpload" | localize }}</a>
  </li>
  <li class="nav-item" *ngIf="isDataOfficer()">
    <a class="nav-link" routerLink="/{{NavigationRoute.ACTIONS}}">{{ "navigationActionsLog" | localize }}</a>
  </li>
  <li class="nav-item" *ngIf="isDataOfficer()">
    <a class="nav-link" routerLink="/{{NavigationRoute.USER_LIST}}">{{ "navigationUserList" | localize }}</a>
  </li>
  <li class="nav-item" *ngIf="isDataOfficer()">
    <a class="nav-link" routerLink="/{{NavigationRoute.ADD_USERS}}">{{ "navigationAddUsers" | localize }}</a>
  </li>
  <li class="nav-item" *ngIf="isNurse() || isDataOfficer()">
    <a class="nav-link"
       routerLink="/{{NavigationRoute.BACKGROUND_INFORMATION}}">{{ "navigationBackgroundInformation" | localize }}</a>
  </li>
  <li class="nav-item" *ngIf="isOrderingNurse()">
    <a class="nav-link" routerLink="/{{NavigationRoute.ORDER_MEASUREMENT}}"
       data-testid="nav-order-measurement">{{ "navigationOrderMeasurement" | localize }}</a>
  </li>
  <li class="nav-item" *ngIf="isDataOfficer()">
    <a class="nav-link" routerLink="/{{NavigationRoute.HOSPITALS}}"
       data-testid="nav-hospitals">{{ "navigationHospitals" | localize }}</a>
  </li>
</ul>
