import {Injectable} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {Observable} from "rxjs";
import {filter, map, skip} from "rxjs/operators";

@Injectable()
export class RouteProvider {

  private route?: string;

  constructor(router: Router) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map((event) => event as NavigationEnd))
      .subscribe({
        next: (event: NavigationEnd) => {
          this.route = event.url;
        },
        error: (error) => console.log(error),
      });
  }

  getLastSegment() {
    if (this.route === undefined) {
      throw new Error("No active route");
    }

    const urlSegments = this.route!.split("/");
    return urlSegments[urlSegments.length - 1];
  }
}
