<button
  type="button"
  data-testid="mailing-info-button"
  class="btn btn-sm btn-secondary mr-4 mb-1"
  (click)="showModal()"
>
  <i class="fa fa-envelope"></i>
</button>

<ng-template #templateMailingInfo>
  <div class="modal-body text-center" data-testid="mailing-info-modal">

    <ng-container *ngIf="gettingMailingInfoFailed">
      <div>{{ "gettingMailingInfoFailureLabel" | localize }}</div>
    </ng-container>

    <ng-container *ngIf="!gettingMailingInfoFailed && mailingInfo===undefined">
      <loading-indicator></loading-indicator>
    </ng-container>

    <div
      *ngIf="mailingInfo!==undefined"
      class="m-3 text-left"
    >
      <div class="row mb-2">
        <div class="strong col-4">{{ "mailingInfoNameLabel" | localize }}</div>
        <div class="multiline ml-3">
          <span>{{ mailingInfo.name }}</span>
        </div>
      </div>

      <div class="row mb-2">
        <div class="strong col-4">{{ "mailingInfoStreetAddressLabel" | localize }}</div>
        <div class="multiline ml-3">
          <span>{{ mailingInfo.streetAddress }}</span>
        </div>
      </div>

      <div class="row mb-2">
        <div class="strong col-4">{{ "mailingInfoPostalCodeLabel" | localize }}</div>
        <div class="multiline ml-3">
          <span>{{ mailingInfo.postalCode }}</span>
        </div>
      </div>

      <div class="row mb-2">
        <div class="strong col-4">{{ "mailingInfoPostalAreaLabel" | localize }}</div>
        <div class="multiline ml-3">
          <span>{{ mailingInfo.postalArea }}</span>
        </div>
      </div>


      <div class="row mb-2">
        <div class="strong col-4">{{ "mailingInfoPhoneNumberLabel" | localize }}</div>
        <div class="multiline ml-3">
          <span>{{ mailingInfo.phoneNumber }}</span>
        </div>
      </div>

      <div class="row mb-2">
        <div class="strong col-4">{{ "mailingInfoEmailAddressLabel" | localize }}</div>
        <div class="multiline ml-3">
          <span>{{ mailingInfo.emailAddress }}</span>
        </div>
      </div>

    </div>

    <button
      data-testId="close-mailing-info-modal-button"
      class="btn btn-primary mt-3"
      (click)="closeModal()"
    >
      {{ "closeButtonLabel" | localize }}
    </button>
  </div>
</ng-template>
