import {Component, OnInit} from "@angular/core";
import {RouteNavigator} from "../../tools/navigation/route-navigator.service";
import {NavigationRoute} from "../../tools/navigation/navigation-route";
import {MailDeviceService} from "./mail-device.service";
import {MeasurementIdProvider} from "../analize/measurement-id-provider";
import {ActivatedRoute} from "@angular/router";
import {Notifications, NotificationType} from "../../tools/notifications/notifications";
import {finalize} from "rxjs/operators";
import {FormConfigBase} from "../../tools/form/form-config-base";
import {PropertyChangeListener} from "../../tools/form/form-field-config";
import {FormValidator} from "../../tools/form/form-validator";
import {isRequiredTextOfMaxLength} from "../validations";
import {MAX_CHARACTERS_IN_TEXT} from "../utils";
import {ErrorCode, ErrorResponse, singleCodeFromResponse} from "../../tools/error-response";
import {ClipboardService} from "ngx-clipboard";
import {MailingInfo} from "../e-forms/form/mailing-info/e-form-mailing-info.service";

@Component({
  selector: "app-mail-device",
  templateUrl: "./mail-device.component.html",
})
export class MailDeviceComponent implements OnInit {

  private measurementId!: number;
  confirmingInProgress = false;

  mailDeviceModel: MailDeviceModel = {
    deviceSerialNumber: ""
  };
  private validator = new MailDeviceValidator(this.mailDeviceModel);
  readonly formConfig: MailDeviceFormConfig;
  mailingInfoPrinted = false;


  constructor(
    private readonly routeNavigator: RouteNavigator,
    private readonly mailDeviceService: MailDeviceService,
    private readonly measurementIdProvider: MeasurementIdProvider,
    private readonly activatedRoute: ActivatedRoute,
    private readonly notifications: Notifications,
    private readonly clipboardService: ClipboardService
  ) {
    const self = this;

    const propertyChangeListener: PropertyChangeListener<MailDeviceModel> = {
      onPropertyChange(property: keyof MailDeviceModel, newValue: any) {
        if (property === "deviceSerialNumber") {
          self.validator.resetDeviceSerialNumberValidation();
        }
        self.mailDeviceModel[property] = newValue as never;
        self.validator.updatePropertyValidation(property);
      }
    };

    this.formConfig = new MailDeviceFormConfig(
      this.mailDeviceModel,
      propertyChangeListener,
      (property) => this.validator.isPropertyValid(property)
    );
  }

  ngOnInit(): void {
    this.measurementId = this.measurementIdProvider.getId(this.activatedRoute)!;
  }

  confirmDeviceMailing() {
    this.validator.updateAllValidations();
    if (!this.validator.isFormValid()) {
      return;
    }

    this.confirmingInProgress = true;
    this
      .mailDeviceService
      .confirmDeviceMailing(
        {
          measurementId: this.measurementId,
          deviceSerialNumber: this.mailDeviceModel.deviceSerialNumber
        }
      )
      .pipe(
        finalize(() => this.confirmingInProgress = false)
      )
      .subscribe(
        {
          next: () => {
            this.notifications.addNotification(NotificationType.OK, "mailingDeviceConfirmationSuccess");
            this.routeNavigator.navigateTo(NavigationRoute.LIST);
          },
          error: (errorResponse: ErrorResponse) => {
            if (singleCodeFromResponse(errorResponse) === ErrorCode.DEVICE_ALREADY_IN_USE) {
              this.validator.invalidateDeviceSerialNumber();
            } else {
              this.notifications.addNotification(NotificationType.ERROR, "mailingDeviceConfirmationFailure");
            }
          }
        }
      );
  }

  printMailingInfo() {
    this
      .mailDeviceService
      .getPatientMailingInfo(this.measurementId)
      .subscribe({
        next: (mailingInfo) => {
          this.clipboardService.copy(formatMailingInfo(mailingInfo));
          this.notifications.addNotification(NotificationType.OK, "mailingConfirmationPrintingMailingInfoSuccess");
          this.mailingInfoPrinted = true;
        },
        error: () => {
          this.notifications.addNotification(NotificationType.ERROR, "mailingConfirmationPrintingMailingInfoFailure");
        }
      });
  }
}

class MailDeviceValidator extends FormValidator<MailDeviceModel> {

  overrideDeviceSerialNumberInvalid = false;

  constructor(model: MailDeviceModel) {
    super(model, new Map<keyof MailDeviceModel, (value: any) => boolean>()
      .set("deviceSerialNumber", (value: string) =>
        !this.overrideDeviceSerialNumberInvalid
        && isRequiredTextOfMaxLength(
          MAX_CHARACTERS_IN_TEXT,
          value)
      )
    );
  }

  invalidateDeviceSerialNumber() {
    this.overrideDeviceSerialNumberInvalid = true;
    this.updatePropertyValidation("deviceSerialNumber");
  }

  resetDeviceSerialNumberValidation() {
    this.overrideDeviceSerialNumberInvalid = false;
  }
}

class MailDeviceFormConfig extends FormConfigBase<MailDeviceModel> {

  constructor(
    model: MailDeviceModel,
    propertyChangeListener: PropertyChangeListener<MailDeviceModel>,
    validationProvider: (property: keyof MailDeviceModel) => (boolean | undefined)
  ) {
    super(model, propertyChangeListener, validationProvider);
  }

  readonly deviceSerialNumber = this.createField({
    property: "deviceSerialNumber",
    label: "mailDevicePageLabelDeviceSerialNumber",
    type: "text",
    required: true,
    invalidLabel: "mailDevicePageDeviceSerialNumberError"
  });

}

interface MailDeviceModel {
  deviceSerialNumber: string;
}

export function formatMailingInfo(mailingInfo: MailingInfo): string {
  return [
    mailingInfo.name,
    mailingInfo.streetAddress,
    mailingInfo.postalCode,
    mailingInfo.postalArea,
    mailingInfo.phoneNumber,
    mailingInfo.emailAddress
  ]
    .filter(text => text !== undefined && text.length > 0)
    .join(" ");
}
