import {HomeMeasurementStatus} from "../../../list/found-measurement";
import {ReleaseToggleState} from "../../../../tools/release-toggles/release-toggle-state";


const statusesForWhichShown: HomeMeasurementStatus[] = [
  "WAITING_FOR_MAILING_INFO",
  "MAILING_TO_PATIENT"
];

export function shouldShowPreMeasurementFields(homeStatus: HomeMeasurementStatus | undefined) {
  return homeStatus === undefined
    || statusesForWhichShown.includes(homeStatus);
}
