import {HomeMeasurementStatus} from "../../found-measurement";
import {UserRole} from "../../../authentication/authenticated-user";


const allowedStatuses: HomeMeasurementStatus[] = [
  "MAILED_TO_PATIENT",
  "RETURNED_FROM_PATIENT"
];

export function shouldShowMailingInfoButton(role: UserRole, homeStatus: HomeMeasurementStatus | undefined): boolean {
  if (homeStatus === undefined) {
    return false;
  }

  return role === "HMO" && allowedStatuses.includes(homeStatus);

}
