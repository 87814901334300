import {Component, Input, OnInit} from "@angular/core";
import {isEcgMeasurement, ReportType} from "../../../measurement-models";
import {getReportType} from "../get-report-type";
import {emptyListItem, ReportDownloadAction} from "../list-item.component";
import {ReportFilenameFactory} from "../../report/download/report-filename-factory.service";
import {ListItem} from "../../list-item";
import {AuthenticatedUser} from "../../../authentication/authenticated-user";
import {AuthenticationService} from "../../../authentication/authentication.service";

@Component({
  selector: "app-download-report",
  templateUrl: "./download-report.component.html",
})
export class DownloadReportComponent implements OnInit {

  @Input() item: ListItem = emptyListItem();

  private user!: AuthenticatedUser;

  constructor(
    private readonly reportFilenameFactory: ReportFilenameFactory,
    private readonly authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.user = this.authenticationService.getCurrentAuthenticatedUser()!;
  }

  hasReport(): boolean {
    return this.reportAllowed();
  }

  hasSecondaryReport(): boolean {
    if (!isEcgMeasurement(this.item.foundMeasurement.type)) {
      return false;
    }

    if ((this.user.role === "DATA_OFFICER") && this.item.foundMeasurement.combinedReportExists) {
      return false;
    }

    if ((this.user.role === "DOCTOR")) {
      return false;
    }

    if ((this.user.role === "NURSE" || this.user.role === "ORDERING_NURSE")
      && this.item.foundMeasurement.combinedReportExists
      && this.user.canAccessCombinedReports) {
      return false;
    }

    return this.reportAllowed();
  }


  private reportAllowed(): boolean {
    const ordered = this.item.foundMeasurement.hasOperatorHospital;
    const role = this.user.role;
    const status = this.item.foundMeasurement.status;
    const isChainDoctor = this.user.isChainDoctor;

    switch (role) {
      case "NURSE":
        return status === "DONE" && !ordered;
      case "ORDERING_NURSE":
        return status === "DONE" && ordered;
      case "DATA_OFFICER":
        return status === "DONE" || status === "READY_FOR_POST_ANALYSIS";
      case "DOCTOR":
        return status === "DONE" && isChainDoctor;
      default:
        throw Error(`${role} should not have download report component!`);
    }
  }

  getDownloadUrl(reportDownloadAction: ReportDownloadAction): string {

    const fileName = this.reportFilenameFactory.createFilename(this.item.foundMeasurement, reportDownloadAction);

    const reportType: ReportType = getReportType(
      this.user.role,
      reportDownloadAction,
      this.item.foundMeasurement.combinedReportExists,
      this.user.canAccessCombinedReports
    );

    // eslint-disable-next-line max-len
    return `/api/measurements/${this.item.foundMeasurement.id}/report/${reportType}/download/${encodeURI(fileName)}?token=${this.user.token}`;
  }

  markReportDownloadedForNurse(): void {
    if (this.user.role === "NURSE" || this.user.role === "ORDERING_NURSE") {
      this.item.foundMeasurement = {...this.item.foundMeasurement, nurseDownloadedReport: true};
    }
  }

  showNurseDownloadedReport(): boolean {
    if (this.item.foundMeasurement.nurseDownloadedReport) {
      return false;
    }

    return this.user.role === "NURSE"
      || this.user.role === "ORDERING_NURSE"
      || this.user.role === "DATA_OFFICER";
  }
}
