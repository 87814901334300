import {Component} from "@angular/core";
import {Notifications} from "./notifications";
import {Scheduler} from "./scheduler";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent {
  private nextId = 0;

  readonly notificationItems: NotificationItem[] = [];

  constructor(notifications: Notifications, scheduler: Scheduler) {
    notifications.deleteAll$.subscribe(() => this.notificationItems.splice(0, this.notificationItems.length));

    notifications.added$.subscribe((newNotification) => {
      const id = this.nextId++;

      this.notificationItems.push({
        id,
        message: newNotification.message,
        type: String(newNotification.type),
      });

      if (newNotification.timeoutMillis !== undefined) {
        scheduler.schedule(() => this.deleteNotification(id), newNotification.timeoutMillis);
      }
    });
  }

  deleteNotification(notificationId: number) {
    const index = this.notificationItems.findIndex((item) => item.id === notificationId);

    if (index < 0) {
      return;
    }

    this.notificationItems.splice(index, 1);
  }
}

export interface NotificationItem {
  id: number;
  type: string;
  message: string;
}
